import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/shared/service/products.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.scss']
})
export class MaterialListComponent  {

  public materialsList = []

  constructor(
    private productService: ProductsService,
    private router: Router,

  ) {
    //Get Categories
    this.loadMaterials();
  }

  loadMaterials() {
    this.productService.getMaterials().subscribe(response => { this.materialsList = response; });
  }


  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },
    pager: {
      perPage: 25,
    },
    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    columns: {
      AR_Name: {
        title: 'اسم الخامة'
      },
      AR_Description: {
        title: 'تفاصيل الخامة',
      },
    },
  };


  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.productService.deleteMaterial(event.data.MaterialID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {
    if (window.confirm('Are you sure you want to Edit?')) {

      //Show Edit Window
      this.router.navigate(["/products/edit-material", event.data.MaterialID]);
    }
  }

}
