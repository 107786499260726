import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/shared/service/settings.service';
import { PartsService } from 'src/app/shared/service/parts.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part-list',
  templateUrl: './part-list.component.html',
  styleUrls: ['./part-list.component.scss']
})
export class PartListComponent implements OnInit {

  public partList = []

  constructor(
    private partsService: PartsService,
    private router: Router,

  ) {
    //Get Categories
    this.loadParts();
  }

  loadParts() {
    this.partsService.getParts().subscribe(response => {
      this.partList = response;
    });
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },
    pager: {
      perPage: 25,
    },
    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    columns: {
      AR_Name: {
        title: 'اسم الفرع'
      },
      AR_Address:
      {
        title: 'عنوان الفرع'
      },
      AR_Description:
      {
        title: 'تفاصيل الفرع'
      },
    },
  };

  ngOnInit() { }


  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.partsService.deletePart(event.data.ID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {
    if (window.confirm('Are you sure you want to Edit?')) {

      //Show Edit Window
      this.router.navigate(["/design/edit-part/", event.data.ID]);
    }
  }
}
