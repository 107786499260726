import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CategoryService } from 'src/app/shared/service/category.service';
import { ProductModel } from 'src/app/shared/classes/productModel';
import { ProductsService } from 'src/app/shared/service/products.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ColorModel } from 'src/app/shared/classes/colorModel';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { MaterialModel } from 'src/app/shared/classes/materialModel';
import { CategoryModel } from 'src/app/shared/classes/categoryModel';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  isNew: boolean = true;
  isSubmit: boolean = false;
  product: ProductModel;
  progress: number = 0;

  defaultImagePath = "uploads/default/defaultProduct.jpg";
  defaultFlipImagePath = "uploads/default/defaultProductFlip.jpg";

  selectedFile = null;
  selectedFlipFile = null;

  imageFile;
  imageFileFlip;

  multipleFiles: any[] = [];
  removedFiles: number[] = [];

  categories = [];
  arTags: string[] = [];
  enTags: string[] = [];


  filteredColors: Observable<ColorModel[]>;
  colors: ColorModel[] = [];
  allColors: ColorModel[] = [];

  filteredMaterials: Observable<MaterialModel[]>;
  materials: MaterialModel[] = [];
  allMaterials: MaterialModel[] = [];

  readonly separatorKeysCodes: number[] = [ENTER];
  public config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 20,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    acceptedFiles: 'image/*',
    addRemoveLinks: true,
  };
  dropzone: any;


  @ViewChild('colorInput') colorInput: ElementRef<HTMLInputElement>;
  @ViewChild('materialInput') materialInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoColor') matColorAutocomplete: MatAutocomplete;
  @ViewChild('autoMaterial') matMaterialAutocomplete: MatAutocomplete;

  myForm = new FormGroup({
    controlArName: new FormControl("", [Validators.required]),
    controlEnName: new FormControl("", [Validators.required]),
    controlCategory: new FormControl("", [Validators.required]),
    controlColor: new FormControl(),
    controlCode: new FormControl(),
    controlMaterial: new FormControl(),

    controlArDescription: new FormControl(),
    controlEnDescription: new FormControl(),
    controlArTags: new FormControl(),
    controlEnTags: new FormControl(),

    controlPrice: new FormControl(),
    controlPriceBeforeDiscount: new FormControl(),
    controlQuantity: new FormControl(),

    controlIsSale: new FormControl(),
    controlIsSpecial: new FormControl(),
    controlIsNew: new FormControl(),
  });

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }
  compareFn(x: CategoryModel, y: number): boolean {
    return x && y ? x.CategoryID === y : x.CategoryID === y;
  }

  constructor(
    private categoryService: CategoryService,
    private productService: ProductsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    //Get Categories
    this.route.data.subscribe(data => {
      this.isNew = data.isNew;
      this.imageFile = environment.apiUrl + "/" + this.defaultImagePath;
      this.imageFileFlip = environment.apiUrl + "/" + this.defaultFlipImagePath;

      if (!this.isNew) {
        this.route.paramMap.subscribe(params => {
          const id = params.get('id');

          this.productService.getProductByID(id).subscribe(e => {
            this.product = e[0];
            this.imageFile = this.product.ImageUrl;
            this.imageFileFlip = this.product.FlipImageUrl;

            this.myForm.patchValue({
              controlArName: this.product.AR_Name,
              controlEnName: this.product.EN_Name,
              controlCode: this.product.Code,
              controlCategory: this.product.Category.CategoryID,

              controlArDescription: this.product.AR_Description,
              controlEnDescription: this.product.EN_Description,

              controlPrice: this.product.Price,
              controlPriceBeforeDiscount: this.product.PriceBeforeDiscount,
              controlQuantity: this.product.Quantity,

              controlIsSale: this.product.IsSale,
              controlIsSpecial: this.product.IsSpecial,
              controlIsNew: this.product.IsNew,
            });

            this.arTags = this.product?.AR_Tags?.split(',').filter(e => e != "");
            this.enTags = this.product?.EN_Tags?.split(',').filter(e => e != "");
          });
        });
      }
    });

    this.loadCategories();
    this.loadColors();
    this.loadMaterials();
  }

  loadColors() {
    this.productService.getColors().subscribe(colorResult => {
      this.allColors = colorResult;

      if (!this.isNew) {
        this.product?.Colors.forEach(e => {
          this.addColorToColors(e.Color);
        });
      }

      this.filteredColors = this.myForm.controls.controlColor.valueChanges.pipe(
        startWith(null),
        map((color: ColorModel | null) => color ? this._filterColors(color.AR_Name) : this.allColors.slice()));
    })
  }

  loadMaterials() {
    this.productService.getMaterials().subscribe(materialResult => {
      this.allMaterials = materialResult;
      if (!this.isNew) {

        this.product?.Materials.forEach(e => {

          this.addMatrialToMaterials(e.Material);
        });
      }

      this.filteredMaterials = this.myForm.controls.controlMaterial.valueChanges.pipe(
        startWith(null),
        map((material: MaterialModel | null) => material ? this._filterMaterials(material.AR_Name) : this.allMaterials.slice()));
    })
  }

  loadCategories() {
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response.filter(e => e.ParentID != 0);
    });
  }

  onCategoryChange(event) {
    let category: CategoryModel = event.value;

    if (category.AR_Tags) {
      category.AR_Tags.split(",").forEach(tag => {
        this.addTagToProduct("ar", tag);
      });
    }

    if (category.EN_Tags) {
      category.EN_Tags.split(",").forEach(tag => {
        this.addTagToProduct("en", tag);
      });
    }
  }

  addTagToProduct(lang: string, tag: string) {
    if (!(tag || '').trim()) return;
    tag = tag.trim().toLowerCase();

    if (lang === "ar") {
      if (!this.arTags.find(e => e.trim().toLowerCase() === tag))
        this.arTags.push(tag);
    }
    else if (lang == 'en') {
      if (!this.enTags.find(e => e.trim().toLowerCase() === tag))
        this.enTags.push(tag);
    }
  }

  addTag(event: MatChipInputEvent, lang: string): void {
    const input = event.input;
    const value = event.value;

    this.addTagToProduct(lang, value);

    if (input) {
      input.value = '';
    }
  }
  removeTag(tag: string, lang: string): void {
    var index = 0;

    switch (lang) {
      case 'ar':
        index = this.arTags.indexOf(tag);
        if (index < 0) return;

        this.arTags.splice(index, 1);
        return;

      case 'en':
        index = this.enTags.indexOf(tag);
        if (index < 0) return;

        this.enTags.splice(index, 1);
        return;
    }
  }

  private _filterColors(value: string): ColorModel[] {
    if (!value || value.trim() === "") return;

    const filterValue = value.toLowerCase();
    return this.allColors.filter(color => color.AR_Name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterMaterials(value: string): MaterialModel[] {
    if (!value || value.trim() === "") return;

    const filterValue = value.toLowerCase();
    return this.allMaterials.filter(material => material.AR_Name.toLowerCase().indexOf(filterValue) === 0);
  }

  addColor(event: MatChipInputEvent): void {
    var _colors = this.allColors.filter(e => e.AR_Name.trim().toLowerCase() === event.value.trim().toLowerCase());
    if (_colors.length <= 0) return;
    var color = _colors[0];

    this.addColorToColors(color);
  }

  selectedColor(event: MatAutocompleteSelectedEvent): void {
    const color = event.option.value;
    this.addColorToColors(color);
  }

  removeColorFromColors(color: ColorModel) {
    if (this.colors.indexOf(color) < 0) return;
    if (this.allColors.indexOf(color) >= 0) return;

    this.allColors.push(color);

    const index = this.colors.indexOf(color);
    if (index >= 0) {
      this.colors.splice(index, 1);
    }

    this.colorInput.nativeElement.value = '';
    this.myForm.controls.controlColor.setValue(null);
  }

  addColorToColors(color: ColorModel) {
    if (this.colors.find(e => e.ID === color.ID)) return;

    color = this.allColors.find(e => e.ID == color.ID);
    if (!color) return;

    this.colors.push(color);
    var index = this.allColors.indexOf(color);

    if (index >= 0) {
      this.allColors.splice(index, 1);
    }

    this.colorInput.nativeElement.value = '';
    this.myForm.controls.controlColor.setValue(null);
  }

  addMaterial(event: MatChipInputEvent): void {
    var _Materials = this.allMaterials.filter(e => e.AR_Name.trim().toLowerCase() === event.value.trim().toLowerCase());
    if (_Materials.length <= 0) return;
    var material = _Materials[0];

    this.addMatrialToMaterials(material);
  }


  selectedMaterial(event: MatAutocompleteSelectedEvent): void {
    const material = event.option.value;
    this.addMatrialToMaterials(material);
  }

  removeMaterialFromMaterials(material: MaterialModel) {
    if (this.materials.indexOf(material) < 0) return;
    if (this.allMaterials.indexOf(material) >= 0) return;

    this.allMaterials.push(material);

    const index = this.materials.indexOf(material);
    if (index >= 0) {
      this.materials.splice(index, 1);
    }

    this.materialInput.nativeElement.value = '';
    this.myForm.controls.controlMaterial.setValue(null);
  }


  addMatrialToMaterials(material: MaterialModel) {
    if (this.materials.find(e => e.MaterialID === material.MaterialID)) return;
    material = this.allMaterials.find(e => e.MaterialID == material.MaterialID);
    if (!material) return;


    this.materials.push(material);
    var index = this.allMaterials.indexOf(material);

    if (index >= 0) {
      this.allMaterials.splice(index, 1);
    }

    this.materialInput.nativeElement.value = '';
    this.myForm.controls.controlMaterial.setValue(null);
  }




  nationalDropZoneInit(dropzone: any): void {
    this.dropzone = dropzone;

    dropzone.on('removedfile', (file) => {
      this.updateImages();

      if (!this.isNew) this.onRemoveFile(file);
    });

    if (this.isNew) return;

    this.product.Images.forEach(element => {

      const mockFile = { image: element };
      dropzone.emit("addedfile", mockFile);
      dropzone.emit("thumbnail", mockFile, environment.apiUrl + "/" + element.ImageUrl);
      dropzone.emit("complete", mockFile);
    })
  }

  public onRemoveFile(file: any): void {
    if (file.accepted) return;

    this.removedFiles.push(file.image._ID);
  }

  public onUploadError(args: any): void {
    this.updateImages();
  }

  public onUploadSuccess(args: any): void {
    this.updateImages();
    this.multipleFiles.push(args[0]);
  }

  updateImages() {
    if (this.dropzone.files) {
      var uploading = this.dropzone.files.filter(e => e.status !== "success");

      if (uploading && uploading.length > 0) {
        this.imagesLeft = uploading.length;
        this.isUploadPending = true;
      }
      else {
        this.imagesLeft = 0;
        this.isUploadPending = false;
      }
    }
  }
  isUploadPending: boolean = false;
  imagesLeft = 0;

  onFileSelected(event, img, type) {
    if (event.target.files.length == 0) return;

    if (event.target.files[0]) {
      const file: File = event.target.files[0];
      var pattern = /image-*/;

      if (!file.type.match(pattern)) {
        alert('Invalid format');
        img.value = "";

        if (type === "file") {
          this.selectedFile = null;
          this.imageFile = environment.apiUrl + this.defaultImagePath;
        }
        else if (type === "flip") {
          this.selectedFlipFile = null;
          this.imageFileFlip = environment.apiUrl + this.defaultFlipImagePath;
        }
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        if (type === "file") {
          this.selectedFile = event.target.files[0];
          this.imageFile = e.target.result;

        }
        else if (type === "flip") {
          this.selectedFlipFile = event.target.files[0];
          this.imageFileFlip = e.target.result;
        }
      }
    }
  }

  // here you can do whatever you want with your image. Now you are sure that it is an image
  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;

    const newProduct: ProductModel = {
      AR_Name: controls.controlArName.value,
      AR_Description: controls.controlArDescription.value,
      AR_Tags: this.arTags.join(),

      EN_Name: controls.controlEnName.value,
      EN_Description: controls.controlEnDescription.value,
      EN_Tags: this.enTags.join(),

      Code: controls.controlCode.value,
      Colors: this.colors,
      Materials: this.materials,

      ImageUrl: this.defaultImagePath,
      FlipImageUrl: this.defaultFlipImagePath,

      IsNew: controls.controlIsNew.value ? true : false,
      IsSpecial: controls.controlIsSpecial.value ? true : false,
      IsSale: controls.controlIsSale.value ? true : false,

      Price: +controls.controlPrice.value ?? 0,
      PriceBeforeDiscount: +controls.controlPriceBeforeDiscount.value ?? 0,
      Quantity: +controls.controlQuantity.value ?? 0,

      CategoryID: controls.controlCategory.value?.CategoryID ?? this.product.CategoryID,
    }
    var formData: any = new FormData();
    formData.append('product', JSON.stringify(newProduct));

    //Call Upload Image then ;
    if (this.selectedFile) {
      formData.append('file', this.selectedFile);
    }

    if (this.selectedFlipFile) {
      formData.append('flip', this.selectedFlipFile);
    }

    if (this.multipleFiles) {
      this.multipleFiles.forEach(element => {
        formData.append('images', element);
      });
    }

    if (this.removedFiles) {
      this.removedFiles.forEach(element => {
        formData.append('removedImages', JSON.stringify(element));
      });
    }

    //Call Upload Image then ;
    if (this.isNew) {
      this.productService.addProduct(formData).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.productService.updateProduct(this.product.ProductID, formData).subscribe((event) => this.saveSubscribe(event));
    }
  }

  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          this.router.navigate(['/products/list-product'])
        }, 2000);
        break;
    }
  }

  isValid(): boolean {
    if (this.isSubmit) return false;

    this.isSubmit = true;

    return true;
  }

  ngOnInit() {
  }

}
