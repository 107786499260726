import { Component, OnInit } from '@angular/core';
import { VisitorsService } from 'src/app/shared/service/visitors.service';

@Component({
  selector: 'app-materials-report',
  templateUrl: './materials-report.component.html',
  styleUrls: ['./materials-report.component.scss']
})
export class MaterialsReportComponent {

  public materialList: { MaterialID: number, AR_Name: string, Views: number }[] = []

  constructor(
    visitorService: VisitorsService,
  ) {
    visitorService.getMaterialsReport().subscribe(response => {
      this.materialList = response;
    });
  }


  public settings = {
    pager: {
      perPage: 50,
    },

    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      // MaterialID: {
      //   title: 'ID',
      //   width: '50px',
      //   filter: false,
      // },
      AR_Name: {
        title: 'اسم الخامة'
      },
      Views: {
        title: 'اجمالي المشاهدات',
        width: '250px',
      },
    },
  };
}