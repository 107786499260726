import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseModel } from '../models/response.model';
import { EmployeeModel } from '../models/employee.model';
import { HelperService } from './helper.service';
import { LoginModel } from '../models/login.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, public helper: HelperService) {}

  login(data: LoginModel): Promise<EmployeeModel> {
    var url = `${environment.apiUrl}/users/login`;
    return new Promise((resolve, reject) => {
      if (data.userName == 'mob23' && data.password == 'mobliana23') {
        var emp: EmployeeModel = {
          name: data.userName,
          isActive: true,
          userTypeID: 1,
        };
        resolve(emp);
      } else {
        reject('user name or password are wrong');
      }
      // this.http
      //   .post<ResponseModel<EmployeeModel>>(url, data)
      //   .pipe(
      //     tap(
      //       (data) => resolve(data),
      //       (error) => reject(error.message)
      //     )
      //   )
      //   .subscribe((result) => {
      //     resolve(result);
      //   });
    });
  }

  // getEmployees(): Observable<ResponseModel<EmployeeModel[]>> {
  //   var url = `${environment.apiUrl}/users/employees`;
  //   return this.http.get<ResponseModel<EmployeeModel[]>>(url);
  // }

  // getEmployeeByID(userID: string): Observable<ResponseModel<EmployeeModel>> {
  //   var url = `${environment.apiUrl}/users/employee/${userID}`;
  //   return this.http.get<ResponseModel<EmployeeModel>>(url);
  // }

  // saveEmployee(
  //   employee: EmployeeModel
  // ): Observable<ResponseModel<EmployeeModel>> {
  //   console.log(employee);
  //   employee.createdBy = +this.helper.currentUser?.id ?? 0;
  //   var url = `${environment.apiUrl}/users/saveEmployee`;
  //   return this.http.post<ResponseModel<EmployeeModel>>(url, employee);
  // }

  // updateEmployee(
  //   employee: EmployeeModel
  // ): Observable<ResponseModel<EmployeeModel>> {
  //   employee.updatedBy = +this.helper.currentUser?.id ?? 0;

  //   var url = `${environment.apiUrl}/users/updateEmployee/${employee.id}/${employee.login.id}`;
  //   return this.http.put<ResponseModel<EmployeeModel>>(url, employee);
  // }

  // delete(id: number): Promise<ResponseModel<any>> {
  //   var deletedByID = this.helper.getCurrentUserID();
  //   var url = `${environment.apiUrl}/users/delete/${id}/${deletedByID}`;

  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .put<ResponseModel<any>>(url, null)
  //       .pipe(
  //         tap(
  //           (data) => resolve(data),
  //           (error) => reject(error.message)
  //         )
  //       )
  //       .subscribe(
  //         (result) => resolve(result),
  //         (error) => reject(error.message)
  //       );
  //   });
  // }

  saveUserImage(userID, image): Observable<ResponseModel<any>> {
    var formData: any = new FormData();
    formData.append('file', image);
    console.log('___________', userID);

    const url = `${environment.apiUrl}/users/updateImage/${userID}`;
    return this.http.post<ResponseModel<any>>(url, formData);
  }
}
