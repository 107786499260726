<div class="loader-container">
    <div class="loader-div loader-div2">
        <div class="loader-div">
            <div class="loader-div">
                <div class="loader-div">
                    <div class="loader-div">
                        <div class="loader-div">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h1 class="text-save">جاري حفظ البيانات </h1>
    <h1 class="text-save"> {{progress}}% </h1>
</div>

<!-- <h1 *ngIf="progress == 100" class="complate">تم الحفظ بنجاح </h1> -->