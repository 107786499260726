<div class="container-fluid" *ngIf="isNew || color">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">

                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="form-group row1col">
                                        <mat-form-field>
                                            <input formControlName="controlArName" matInput
                                                placeholder="اسم اللون - عربي">
                                            <mat-error *ngIf="hasError('controlArName','required')"> برجاء كتابة اسم
                                                اللون </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group row1col">

                                        <mat-form-field>
                                            <input formControlName="controlEnName" matInput
                                                placeholder="اسم اللون - إنجليزي ">
                                            <mat-error *ngIf="hasError('controlEnName','required')"> برجاء كتابة اسم
                                                اللون </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="exam-intro-image col-sm-4 center">
                                    <h6>كود اللون </h6>
                                    <input formControlName="controlColor" matInput class="color-picker" type="color">
                                </div>
                            </div>

                            <div class="form-group row1col space-top">
                                <mat-form-field>
                                    <textarea formControlName="controlArDescription" matInput
                                        placeholder="وصف اللون - عربي">
                                        </textarea>
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <textarea formControlName="controlEnDescription" matInput
                                        placeholder="وصف اللون - انجليزي">
                                    </textarea>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                            <!-- <button class="btn btn-secondary" type="button">Close</button> -->
                        </div>
                    </form>
                    <app-loading *ngIf="isSubmit" [progress]="progress"></app-loading>
                </div>
            </div>
        </div>
    </div>
</div>