import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductsReportComponent } from './products-report/products-report.component';
import { CategoriesReportComponent } from './categories-report/categories-report.component';
import { PartsReportComponent } from './parts-report/parts-report.component';
import { MaterialListComponent } from '../product/materials/material-list/material-list.component';
import { MaterialsReportComponent } from './materials-report/materials-report.component';
import { ColorsReportComponent } from './colors-report/colors-report.component';
import { TagsReportComponent } from './tags-report/tags-report.component';
import { CountriesReportComponent } from './countries-report/countries-report.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: "تقارير موبليانا",
          breadcrumb: "تقارير موبليانا"
        }
      },
      {
        path: 'products',
        component: ProductsReportComponent,
        data: {
          title: "تقارير المنتجات",
          breadcrumb: "تقارير المنتجات"
        }
      },
      {
        path: 'categories',
        component: CategoriesReportComponent,
        data: {
          title: "تقارير الاقسام",
          breadcrumb: "تقارير الاقسام"
        }
      },
      {
        path: 'parts',
        component: PartsReportComponent,
        data: {
          title: "تقارير الفروع",
          breadcrumb: "تقارير الفروع"
        }
      },

      {
        path: 'materials',
        component: MaterialsReportComponent,
        data: {
          title: "تقارير الخامات",
          breadcrumb: "تقارير الخامات"
        }
      },
      {
        path: 'colors',
        component: ColorsReportComponent,
        data: {
          title: "تقارير الالوان",
          breadcrumb: "تقارير الالوان"
        }
      },
      {
        path: 'tags',
        component: TagsReportComponent,
        data: {
          title: 'تقرير الكلمات الدلاليه',
          breadcrumb: 'تقرير الكلمات الدلاليه'
        }
      },
      {
        path: 'countries',
        component: CountriesReportComponent,
        data: {
          title: "تقارير الدول",
          breadcrumb: "تقارير الدول"
        }
      },
    ]
    // {
    //   path: '',
    //   component: ReportsComponent,
    //   pathMatch: 'full',
    //   data: {
    //     title: "Reports",
    //     breadcrumb: "Reports"
    //   }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
