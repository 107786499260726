import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/shared/service/settings.service';
import { PhonesService } from 'src/app/shared/service/phones.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.scss']
})
export class PhoneListComponent implements OnInit {
  public phoneList = []

  constructor(
    private phoneService: PhonesService,
    private router: Router,
  ) {
    //Get Categories
    this.loadPhones();
  }

  loadPhones() {
    this.phoneService.getPhones().subscribe(response => {
      this.phoneList = response;
    });
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },
    pager: {
      perPage: 25,
    },
    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    columns: {
      Phone: {
        title: 'رقم التليفون'
      },
    },
  };

  ngOnInit() { }

  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.phoneService.deletePhones(event.data.ID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {
    if (window.confirm('Are you sure you want to Edit?')) {

      //Show Edit Window
      this.router.navigate(["/design/edit-phone/", event.data.ID]);
    }
  }
}
