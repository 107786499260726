import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductRoutingModule } from './product-routing.module';
import { ProductListComponent } from './products/product-list/product-list.component';
import { AddProductComponent } from './products/add-product/add-product.component';
import { AddCategoryComponent } from './categories/add-category/add-category.component';
import { CategoryListComponent } from './categories/category-list/category-list.component';
import { AddMaterialComponent } from './materials/add-material/add-material.component';
import { MaterialListComponent } from './materials/material-list/material-list.component';
import { ColorListComponent } from './colors/color-list/color-list.component';
import { AddColorComponent } from './colors/add-color/add-color.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ProductListComponent,
    AddProductComponent,
    AddCategoryComponent,
    CategoryListComponent,
    AddMaterialComponent,
    MaterialListComponent,
    ColorListComponent,
    AddColorComponent,
  ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    SharedModule,
  ]
  
})
export class ProductModule { }
