import { Component, OnInit } from '@angular/core';
import { VisitorsService } from 'src/app/shared/service/visitors.service';

@Component({
  selector: 'app-tags-report',
  templateUrl: './tags-report.component.html',
  styleUrls: ['./tags-report.component.scss']
})
export class TagsReportComponent {

  public tagsList: { TagID: number, Name: string, Views: number }[] = []

  constructor(
    visitorService: VisitorsService,
  ) {
    visitorService.getTagsReport().subscribe(response => {
      this.tagsList = response;
    });
  }


  public settings = {
    pager: {
      perPage: 50,
    },

    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      // TagID: {
      //   title: 'ID',
      //   width: '50px',
      //   filter: false,
      // },
      Name: {
        title: 'الكلمة الدلالية'
      },
      Views: {
        title: 'اجمالي المشاهدات',
        width: '250px',
      },
    },
  };
}