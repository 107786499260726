import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderListComponent } from './slider/slider-list/slider-list.component';
import { AddSliderComponent } from './slider/add-slider/add-slider.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { DesignRoutingModule } from './design-routing.module';
import { NewsListComponent } from './news/news-list/news-list.component';
import { AddNewsComponent } from './news/add-news/add-news.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneListComponent } from './phone/phone-list/phone-list.component';
import { AddPhoneComponent } from './phone/add-phone/add-phone.component';
import { PartListComponent } from './part/part-list/part-list.component';
import { AddPartComponent } from './part/add-part/add-part.component';
import { SocialComponent } from './social/social.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    SliderListComponent,
    AddSliderComponent,
    NewsListComponent,
    AddNewsComponent,
    PhoneListComponent,
    AddPhoneComponent,
    PartListComponent,
    AddPartComponent,
    SocialComponent,
  ],
  imports: [
    CommonModule,
    DesignRoutingModule,
    SharedModule
  ]
})
export class DesignModule { }
