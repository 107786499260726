import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SliderService } from 'src/app/shared/service/slider.service';

@Component({
  selector: 'app-slider-list',
  templateUrl: './slider-list.component.html',
  styleUrls: ['./slider-list.component.scss']
})
export class SliderListComponent implements OnInit {

  public sliderList = []

  constructor(
    private sliderService: SliderService,
    private router: Router
  ) {
    //Get Categories
    this.loadSliders();
  }


  loadSliders() {
    this.sliderService.getSlider().subscribe(response => {
      this.sliderList = response;
      this.sliderList.forEach(e => {
        var newURL = `<img src='${e.ImageUrl}' class='imgTable'>`
        e.ImageUrl = newURL;
      });
    });
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },
    pager: {
      perPage: 25,
    },
    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    columns: {
      ImageUrl: {
        title: 'الصورة',
        type: 'html',
        filter: false,
        width: '100px',
      },
      AR_Title: {
        title: 'العنوان'
      },
      AR_SubTitle:
      {
        title: 'التفاصيل'
      },
    },
  };

  ngOnInit() { }


  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.sliderService.deleteSlider(event.data.ID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {
    if (window.confirm('Are you sure you want to Edit?')) {

      //Show Edit Window
      this.router.navigate(["/design/edit-slider/", event.data.ID]);
    }
  }
}
