import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProductModel } from '../classes/productModel';
import { Observable } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { MaterialModel } from '../classes/materialModel';
import { ColorModel } from '../classes/colorModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(private http: HttpClient,
    private settingService: SettingsService) {
  }

  /*
  ---------------------------------------------
  ---------------  Products  -------------------
  ---------------------------------------------
  */

  getProducts(limit: number, offset: number): Observable<{ count: number, rows: ProductModel[] }> {
    var url = `${environment.apiUrl}/products/select/${limit}/${offset}`;
    return this.http.get<{ count: number, rows: ProductModel[] }>(url);
  }

  getAdminProducts(): Observable<{ count: number, rows: ProductModel[] }> {
    var url = `${environment.apiUrl}/products/select/adminlist`;
    return this.http.get<{ count: number, rows: ProductModel[] }>(url);
  }

  public getProductByID(id: string): Observable<ProductModel> {
    console.log("getProductByID Called");
    return this.http.get<ProductModel>(`${environment.apiUrl}/products/select/byID/${id}`);
  }

  addProduct(formData) {
    var url = `${environment.apiUrl}/products/add`;
    return this.http.post(url, formData, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  updateProduct(id: number, formData) {
    const url = `${environment.apiUrl}/products/update/${id}`;
    return this.http.put(url, formData, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  deleteProduct(id: number) {
    const url = `${environment.apiUrl}/products/delete/${id}`;
    return this.http.put(url, null);
  }

  getMaterials(): Observable<MaterialModel[]> {
    var url = `${environment.apiUrl}/materials/select/`;
    return this.http.get<MaterialModel[]>(url);
  }

  getMaterialByID(id: string): Observable<MaterialModel> {
    console.log("getProductByID Called");
    return this.http.get<MaterialModel>(`${environment.apiUrl}/materials/select/byID/${id}`);
  }

  addMaterial(material: MaterialModel) {
    var url = `${environment.apiUrl}/materials/add`;
    return this.http.post(url, material, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  updateMaterial(id: number, material: MaterialModel) {
    const url = `${environment.apiUrl}/materials/update/${id}`;
    return this.http.put(url, material, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }


  deleteMaterial(id: number) {
    const url = `${environment.apiUrl}/materials/delete/${id}`;
    return this.http.put(url, null);
  }

  getColors(): Observable<ColorModel[]> {
    var url = `${environment.apiUrl}/colors/select/`;
    return this.http.get<ColorModel[]>(url);
  }
  getColorByID(id: string): Observable<ColorModel> {
    console.log("getProductByID Called");
    return this.http.get<ColorModel>(`${environment.apiUrl}/colors/select/byID/${id}`);
  }

  addColor(color: ColorModel) {
    var url = `${environment.apiUrl}/colors/add`;
    return this.http.post(url, color, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  updateColor(id: number, color: ColorModel) {
    const url = `${environment.apiUrl}/colors/update/${id}`;
    return this.http.put(url, color, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  deleteColor(id: number) {
    const url = `${environment.apiUrl}/colors/delete/${id}`;
    return this.http.put(url, null);
  }

}
