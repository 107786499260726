import { Injectable } from '@angular/core';
import { CategoryModel } from '../classes/categoryModel';
import { SettingsService } from './settings.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient,
    private settingService: SettingsService) {
  }

  getCategories(): Observable<CategoryModel[]> {
    var url = `${environment.apiUrl}/categories/select`;
    return this.http.get<CategoryModel[]>(url);
  }

  getMainCategories(): Observable<{ count: number, rows: CategoryModel[] }> {
    var url = `${environment.apiUrl}/categories/main`;
    return this.http.get<{ count: number, rows: CategoryModel[] }>(url);
  }

  public getCategoryByID(id: string): Observable<CategoryModel> {
    return this.http.get<CategoryModel>(`${environment.apiUrl}/categories/select/byID/${id}`);
  }

  // addCategory(data) {
  //   return this.http.post(`${this.apiCategory}/addCategory`, data);
  // }


  addCategory(formData) {
    var url = `${environment.apiUrl}/categories/add`;
    // return this.http.post(url, formData);

    return this.http.post(url, formData, { reportProgress: true, observe: 'events' })
      .pipe(catchError(this.settingService.errorMgmt))
  }

  update(id: number, formData) {
    //Check if Have Image;
    const url = `${environment.apiUrl}/categories/update/${id}`;
    return this.http.put(url, formData, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  delete(id: number) {
    const url = `${environment.apiUrl}/categories/delete/${id}`;
    return this.http.put(url, null);
  }


}
