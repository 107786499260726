import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PartModel } from '../classes/partModel';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartsService {

  constructor(private http: HttpClient,
    private settingService: SettingsService) {
  }

  getParts(): Observable<PartModel[]> {
    var url = `${environment.apiUrl}/parts/select/`;
    return this.http.get<PartModel[]>(url);
  }

  getPartByID(id: string): Observable<PartModel> {
    console.log("getProductByID Called");
    return this.http.get<PartModel>(`${environment.apiUrl}/parts/select/${id}`);
  }

  addPart(part: PartModel) {
    var url = `${environment.apiUrl}/parts/add`;
    return this.http.post(url, part, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  updatePart(id: number, part: PartModel) {
    const url = `${environment.apiUrl}/parts/update/${id}`;
    return this.http.put(url, part, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }
  deletePart(id: number) {
    const url = `${environment.apiUrl}/parts/delete/${id}`;
    return this.http.put(url, null);
  }
}
