import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VisitorModel } from '../classes/VisitorModel';
import { ProductModel } from '../classes/productModel';
import { CategoryModel } from '../classes/categoryModel';
import { ColorModel } from '../classes/colorModel';
import { TagModel } from '../classes/tagModel';
import { MaterialModel } from '../classes/materialModel';
import { PartModel } from '../classes/partModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisitorsService {
  constructor(private http: HttpClient) {
  }

  getReportsByDate(): Observable<{ count: number, rows: VisitorModel[] }> {
    const url = `${environment.apiUrl}/visitors/select/today/`;
    return this.http.get<{ count: number, rows: VisitorModel[] }>(url);
  }
  getReportsProductsByDate(): Observable<{ VisitorsCount: number, Product: ProductModel }[]> {
    const url = `${environment.apiUrl}/visitors/select/todayProducts/`;
    return this.http.get<{ VisitorsCount: number, Product: ProductModel }[]>(url);
  }

  getReportsCategoriesByDate(): Observable<{ VisitorsCount: number, Category: CategoryModel }[]> {
    const url = `${environment.apiUrl}/visitors/select/todayCategory/`;
    return this.http.get<{ VisitorsCount: number, Category: CategoryModel }[]>(url);
  }


  getReportsColorsByDate(): Observable<{ VisitorsCount: number, Color: ColorModel }[]> {
    const url = `${environment.apiUrl}/visitors/select/todayColor/`;
    return this.http.get<{ VisitorsCount: number, Color: ColorModel }[]>(url);
  }
  getReportsTagssByDate(): Observable<{ VisitorsCount: number, Tag: TagModel }[]> {
    const url = `${environment.apiUrl}/visitors/select/todayTag/`;
    return this.http.get<{ VisitorsCount: number, Tag: TagModel }[]>(url);
  }
  getReportsMaterialsByDate(): Observable<{ VisitorsCount: number, Material: MaterialModel }[]> {
    const url = `${environment.apiUrl}/visitors/select/todayMaterial/`;
    return this.http.get<{ VisitorsCount: number, Material: MaterialModel }[]>(url);
  }
  getReportsPartsByDate(): Observable<{ VisitorsCount: number, Part: PartModel }[]> {
    const url = `${environment.apiUrl}/visitors/select/todayPart/`;
    return this.http.get<{ VisitorsCount: number, Part: PartModel }[]>(url);
  }


  getProductsReport(): Observable<{ ProductID: number, AR_Name: string, Views: number }[]> {
    const url = `${environment.apiUrl}/visitors/select/products`;
    return this.http.get<{ ProductID: number, AR_Name: string, Views: number }[]>(url);
  }
  getCategoriesReport(): Observable<{ CategoryID: number, AR_Name: string, Views: number }[]> {
    const url = `${environment.apiUrl}/visitors/select/categories`;
    return this.http.get<{ CategoryID: number, AR_Name: string, Views: number }[]>(url);
  }
  getPartsReport(): Observable<{ ID: number, AR_Name: string, Views: number }[]> {
    const url = `${environment.apiUrl}/visitors/select/parts`;
    return this.http.get<{ ID: number, AR_Name: string, Views: number }[]>(url);
  }

  getColorsReport(): Observable<{ ID: number, AR_Name: string, Views: number }[]> {
    const url = `${environment.apiUrl}/visitors/select/colors`;
    return this.http.get<{ ID: number, AR_Name: string, Views: number }[]>(url);
  }

  getMaterialsReport(): Observable<{ MaterialID: number, AR_Name: string, Views: number }[]> {
    const url = `${environment.apiUrl}/visitors/select/materials`;
    return this.http.get<{ MaterialID: number, AR_Name: string, Views: number }[]>(url);
  }

  getTagsReport(): Observable<{ TagID: number, Name: string, Views: number }[]> {
    const url = `${environment.apiUrl}/visitors/select/tags`;
    return this.http.get<{ TagID: number, Name: string, Views: number }[]>(url);
  }
  getCountriesReport(): Observable<{ Code: string, AR_Name: string, Views: number }[]> {
    const url = `${environment.apiUrl}/visitors/select/countries`;
    return this.http.get<{ Code: string, AR_Name: string, Views: number }[]>(url);
  }

  
  // getCountriesReport(): Observable<{ MaterialID: number, AR_Name: string, Views: number }[]> {
  //   const url = `${environment.apiUrl}/visitors/select/materials`;
  //   return this.http.get<{ MaterialID: number, AR_Name: string, Views: number }[]>(url);
  // }
}
