import { Component, OnInit } from '@angular/core';
import { pieData } from '../../../shared/data/chart';
import * as chartData from '../../../shared/data/chart';
import { VisitorsService } from 'src/app/shared/service/visitors.service';
import { VisitorModel } from 'src/app/shared/classes/VisitorModel';
import { element } from 'protractor';
import { TableRowModel } from 'src/app/shared/classes/tableRowModel';
import { WebSocketService } from 'src/app/shared/service/web-socket.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData;

  ar_Percent = "0";
  en_Percent = "0";

  public pieData = pieData;

  totalViews;
  totalViewers;
  totalCounters;
  totalHomePage;

  products: TableRowModel[] = [];
  categories: TableRowModel[] = [];
  colors: TableRowModel[] = [];
  tags: TableRowModel[] = [];
  materials: TableRowModel[] = [];
  parts: TableRowModel[] = [];

  constructor(private visitorService: VisitorsService
    //,webSocketService: WebSocketService
    ) {
    // webSocketService.listen('connect').subscribe(e => {
    //   console.log(e);
    // })
    visitorService.getReportsByDate().subscribe(data => {
      this.totalViews = data.count;
      var viewrs = data.rows.map(e => { return e.IPAddress });
      this.totalViewers = [...new Set(viewrs)].length;
      var counters = data.rows.map(e => { return e.Country });

      this.totalCounters = [...new Set(counters)].length;
      this.totalHomePage = data.rows.filter(e => e.ItemType == 1).length;

      //get Conteries
      // if (this.totalViews > 0) {
      //   var arLength = data.rows.filter(e => e.Lang === "ar").length;
      //   var ar = ((arLength / this.totalViews) * 100);
      //   var en = 100 - ar;
      //   this.ar_Percent = ar.toFixed(2);
      //   this.en_Percent = en.toFixed(2);

      //   this.doughnutData = [
      //     {
      //       value: ar,
      //       name: "اللغة العربية"

      //     },
      //     {
      //       value: en,
      //       name: "اللغة الانجليزية"
      //     },
      //   ];
      // }

      // this.setCountersChart(data);
      this.setTimeChart(data);
    });

    this.setProductsList();
    this.setCategoriesList();

    this.setColorsList();
    this.setTagsList();
    this.setMaterialsList();
    this.setPartsList();
  }


  // setCountersChart(data: { count: number, rows: VisitorModel[] }) {
  //   this.groupBy(data.rows, e => e.Country).forEach(e => {
  //     var values = [...new Set(e.map(x => { return x.IPAddress }))];
  //     this.countryChart.dataTable.push([[e[0].CountryModel.EN_Name], [values.length], [e.length],]);
  //   })
  // }

  setTimeChart(data: { count: number, rows: VisitorModel[] }) {
    var rows = data.rows.map(e => { return { hour: +(new Date(e.VisitTime.toString()).getHours()) } }).sort((a, b) => { return a.hour - b.hour });
    // var rows =  [...new Set(data.rows.map(e => { return new Date(e.VisitTime.toString()).getHours()  }))];

    this.groupBy(rows, e => e.hour).forEach(e => {
      this.timeChartLabels.push(e[0].hour);
      this.timeChartData[0].data.push(e.length);
    })
  }

  setProductsList() {
    this.visitorService.getReportsProductsByDate().subscribe(data => {
      if (!data) return;

      data.forEach(e => {
        this.products.push({ ID: e.Product.ProductID, Name: e.Product.AR_Name, Views: e.Product.Views, VisitorsCount: e.VisitorsCount })
      });
    });
  }
  setCategoriesList() {
    this.visitorService.getReportsCategoriesByDate().subscribe(data => {
      if (!data) return;

      data.forEach(e => {
        this.categories.push({ ID: e.Category.CategoryID, Name: e.Category.AR_Name, Views: e.Category.Views, VisitorsCount: e.VisitorsCount })
      });
    });
  }
  setColorsList() {
    this.visitorService.getReportsColorsByDate().subscribe(data => {
      if (!data) return;

      data.forEach(e => {
        this.colors.push({ ID: e.Color.ID, Name: e.Color.AR_Name, Views: e.Color.Views, VisitorsCount: e.VisitorsCount })
      });
    });
  }
  setTagsList() {
    this.visitorService.getReportsTagssByDate().subscribe(data => {
      if (!data) return;

      data.forEach(e => {
        this.tags.push({ ID: e.Tag.TagID, Name: e.Tag.Name, Views: e.Tag.Views, VisitorsCount: e.VisitorsCount })
      });
    });
  }
  setMaterialsList() {
    this.visitorService.getReportsMaterialsByDate().subscribe(data => {
      if (!data) return;

      data.forEach(e => {
        this.materials.push({ ID: e.Material.MaterialID, Name: e.Material.AR_Name, Views: e.Material.Views, VisitorsCount: e.VisitorsCount })
      });
    });
  }
  setPartsList() {
    this.visitorService.getReportsPartsByDate().subscribe(data => {
      if (!data) return;
      data.forEach(e => {
        this.parts.push({ ID: e.Part.ID, Name: e.Part.AR_Name, Views: e.Part.Views, VisitorsCount: e.VisitorsCount })
      });
    });
  }

  //line chart
  timeChartData: Array<any> = [
    { data: [] },
  ];

  timeChartLabels: Array<any> = [];


  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  countryChart: any = {
    chartType: 'ColumnChart',
    dataTable: [
      ["Country", "Views", "Visitors"],
      ["", 0, 0],
    ],
    options: {
      legend: { position: 'none' },
      bars: "vertical",
      vAxis: {
        format: "decimal"
      },
      height: 400,
      width: '100%',
      colors: ["#13c9ca", "#ffbc58"],
      backgroundColor: 'transparent'
    },
  };




  lineChartOptions: any = {
    scaleShowGridLines: true,
    scaleGridLineWidth: 1,
    scaleShowHorizontalLines: true,
    scaleShowVerticalLines: true,
    bezierCurve: true,
    bezierCurveTension: 0.4,
    pointDot: true,
    pointDotRadius: 4,
    pointDotStrokeWidth: 1,
    pointHitDetectionRadius: 20,
    datasetStroke: true,
    datasetStrokeWidth: 2,
    datasetFill: true,
    responsive: true,
    maintainAspectRatio: false,
  };
  lineChartColors: Array<any> = [
    {
      backgroundColor: "transparent",
      borderColor: "#ffbc58",
      pointColor: "#ffbc58",
      pointStrokeColor: "#fff",
      pointHighlightFill: "#000",
      pointHighlightStroke: "rgba(30, 166, 236, 1)",
    },
  ];
  lineChartLegend = false;
  lineChartType = 'line';

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartColors = chartData.smallLineChartColors;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartColors = chartData.smallLine2ChartColors;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartColors = chartData.smallLine3ChartColors;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;



  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
  }

}
