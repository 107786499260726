import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  isSubmit: boolean = false;


  myForm = new FormGroup({
    controlFacebook: new FormControl(""),
    controlYoutube: new FormControl(""),
    controlTwitter: new FormControl(""),
    controlInstagram: new FormControl(""),
    controlWhatsApp: new FormControl(""),
    controlGoogle: new FormControl(""),
  });

  constructor() { }

  ngOnInit(): void {
  }


  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

  }

  redirctToList() {
    //Redirct to main 
    // this.router.navigate(['/design/list-sliders'])
  }

  isValid(): boolean {
    // if (this.isSubmit) return false;
    // this.isSubmit = true;

    return true;
  }

}
