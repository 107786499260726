import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SliderModel } from 'src/app/shared/classes/sliderModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SliderService } from 'src/app/shared/service/slider.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-slider',
  templateUrl: './add-slider.component.html',
  styleUrls: ['./add-slider.component.scss']
})
export class AddSliderComponent implements OnInit {

  isNew: boolean = true;
  slider: SliderModel;
  isSubmit: boolean = false;
  progress = 0;

  selectedFile = null;
  selectedMobileFile = null;

  imageFile;
  imageFileMobile;

  defaultImagePath = "uploads/default/defaultSlider.jpg";
  defaultMobileImagePath = "uploads/default/defaultSliderMobile.jpg";

  myForm = new FormGroup({
    controlArTitle: new FormControl("", [Validators.required]),
    controlEnTitle: new FormControl("", [Validators.required]),
    controlColorTitle: new FormControl("#ff0000"),

    controlArSubTitle: new FormControl("", [Validators.required]),
    controlEnSubTitle: new FormControl("", [Validators.required]),
    controlColorSubTitle: new FormControl("#fff000"),

    controlArButtonTitle: new FormControl("", [Validators.required]),
    controlEnButtonTitle: new FormControl("", [Validators.required]),

    controlArLink: new FormControl("", [Validators.required]),
    controlEnLink: new FormControl("", [Validators.required]),
  });

  constructor(
    private silderService: SliderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    //CheckForEdit;
    this.route.data.subscribe(data => {
      this.isNew = data.isNew;
      this.imageFile = environment.apiUrl + "/" + this.defaultImagePath;
      this.imageFileMobile = environment.apiUrl+ "/" + this.defaultMobileImagePath;

      this.route.paramMap.subscribe(params => {
        const id = params.get('id');

        if (!this.isNew)
          this.silderService.getSliderByID(id).subscribe(e => {
            this.slider = e;
            this.imageFile = environment.apiUrl+ "/" + this.slider.ImageUrl;
            this.imageFileMobile = environment.apiUrl+ "/" + this.slider.Mobile_ImageUrl;

            this.myForm.patchValue({
              controlArTitle: this.slider.AR_Title,
              controlEnTitle: this.slider.EN_Title,
              controlColorTitle: this.slider.Color_Title,

              controlArSubTitle: this.slider.AR_SubTitle,
              controlEnSubTitle: this.slider.EN_SubTitle,
              controlColorSubTitle: this.slider.Color_SubTitle,

              controlArButtonTitle: this.slider.AR_ButtonTitle,
              controlEnButtonTitle: this.slider.EN_ButtonTitle,

              controlArLink: this.slider.AR_Link,
              controlEnLink: this.slider.EN_Link,
            });
          });
      });
    });
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }

  onFileSelected(event, img, type) {
    if (event.target.files.length == 0) return;

    if (event.target.files[0]) {
      const file: File = event.target.files[0];
      var pattern = /image-*/;

      if (!file.type.match(pattern)) {
        alert('Invalid format');
        img.value = "";

        if (type === "file") {
          this.selectedFile = null;
          this.imageFile = environment.apiUrl+ this.defaultImagePath;
        }
        else if (type === "flip") {
          this.selectedMobileFile = null;
          this.imageFileMobile = environment.apiUrl+ this.defaultMobileImagePath;
        }
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        if (type === "file") {
          this.selectedFile = event.target.files[0];
          this.imageFile = e.target.result;

        }
        else if (type === "mobile") {
          this.selectedMobileFile = event.target.files[0];
          this.imageFileMobile = e.target.result;
        }
      }
    }
  }


  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;
    const newSlider: SliderModel = {
      AR_Title: controls.controlArTitle.value,
      EN_Title: controls.controlEnTitle.value,
      Color_Title: controls.controlColorTitle.value,

      AR_SubTitle: controls.controlArSubTitle.value,
      EN_SubTitle: controls.controlEnSubTitle.value,
      Color_SubTitle: controls.controlColorSubTitle.value,

      AR_ButtonTitle: controls.controlArButtonTitle.value,
      EN_ButtonTitle: controls.controlEnButtonTitle.value,

      AR_Link: controls.controlArLink.value,
      EN_Link: controls.controlEnLink.value,

      ImageUrl: this.isNew ? this.defaultImagePath : this.slider?.ImageUrl,
      Mobile_ImageUrl: this.isNew ? this.defaultMobileImagePath : this.slider?.Mobile_ImageUrl,
    }


    //Call Upload Image then ;
    var formData = new FormData();
    formData.append('slider', JSON.stringify(newSlider));

    //Call Upload Image then ;
    if (this.selectedFile) {
      formData.append('file', this.selectedFile);
    }

    if (this.selectedMobileFile) {
      formData.append('mobile', this.selectedMobileFile);
    }

    if (this.isNew) {
      this.silderService.addSlider(formData).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.silderService.updateSlider(this.slider.ID, formData).subscribe((event) => this.saveSubscribe(event));
    }
  }

  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          this.router.navigate(['/design/list-sliders'])
        }, 2000);
        break;
    }
  }


  isValid(): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;

    return true;
  }

  ngOnInit() {
  }

}
