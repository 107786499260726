<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>إضافة منتج جديد</h5>
                </div> -->
                <div class="card-body">

                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">

                        <div class="modal-body">

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArName" matInput placeholder="اسم المنتج - عربي">
                                    <mat-error *ngIf="hasError('controlArName','required')"> برجاء كتابة اسم
                                        المنتج </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnName" matInput placeholder="اسم المنتج - إنجليزي ">
                                    <mat-error *ngIf="hasError('controlEnName','required')"> برجاء كتابة اسم
                                        المنتج </mat-error>

                                </mat-form-field>
                            </div>

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <textarea rows="5" formControlName="controlArDescription" matInput
                                        placeholder="وصف المنتج - عربي">
                                        </textarea>
                                </mat-form-field>

                                <mat-form-field>
                                    <textarea rows="5" formControlName="controlEnDescription" matInput
                                        placeholder="وصف المنتج - انجليزي">
                                    </textarea>
                                </mat-form-field>
                            </div>

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <mat-label>القسم </mat-label>
                                    <mat-select formControlName="controlCategory"
                                        (selectionChange)="onCategoryChange($event)" [compareWith]="compareFn">
                                        <mat-option *ngFor="let cate of categories" [value]="cate">
                                            {{cate.AR_Name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('controlCategory','required')"> برجاء اختيار
                                        القسم </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlCode" matInput placeholder="الكود">
                                </mat-form-field>
                            </div>

                            <div *ngIf="allColors" class="form-group row1col">

                                <mat-form-field class="example-chip-list">
                                    <mat-chip-list #colorChipList aria-label="Color selection">
                                        <mat-chip *ngFor="let color of colors" (removed)="removeColorFromColors(color)">
                                            {{color.AR_Name}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="...الالوان" #colorInput formControlName="controlColor"
                                            [matAutocomplete]="autoColor" [matChipInputFor]="colorChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="addColor($event)">
                                    </mat-chip-list>
                                    <mat-autocomplete #autoColor="matAutocomplete"
                                        (optionSelected)="selectedColor($event)">
                                        <mat-option *ngFor="let color of filteredColors | async" [value]="color">
                                            {{color.AR_Name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>

                            <div *ngIf="allMaterials" class="form-group row1col">

                                <mat-form-field class="example-chip-list">
                                    <mat-chip-list #materialChipList aria-label="material selection">
                                        <mat-chip *ngFor="let material of materials"
                                            (removed)="removeMaterialFromMaterials(material)">
                                            {{material.AR_Name}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="...الخامات" #materialInput formControlName="controlMaterial"
                                            [matAutocomplete]="autoMaterial" [matChipInputFor]="materialChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="addMaterial($event)">
                                    </mat-chip-list>
                                    <mat-autocomplete #autoMaterial="matAutocomplete"
                                        (optionSelected)="selectedMaterial($event)">
                                        <mat-option *ngFor="let material of filteredMaterials | async"
                                            [value]="material">
                                            {{material.AR_Name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>


                            <div class="form-group row1col">
                                <mat-form-field class="example-chip-list">
                                    <mat-chip-list #chipList aria-label="Fruit selection">
                                        <mat-chip *ngFor="let tag of arTags" [selectable]="false" [removable]="true"
                                            (removed)="removeTag(tag,'ar')">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="الكلمات الدلالية - عربي " [matChipInputFor]="chipList"
                                            formControlName="controlArTags"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addTag($event,'ar')">
                                    </mat-chip-list>
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field class="example-chip-list">
                                    <mat-chip-list #enTagControl aria-label="Fruit selection">
                                        <mat-chip *ngFor="let tag of enTags" [selectable]="false" [removable]="true"
                                            (removed)="removeTag(tag,'en')">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="الكلمات الدلالية - انجليزي "
                                            [matChipInputFor]="enTagControl" formControlName="controlEnTags"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addTag($event,'en')">
                                    </mat-chip-list>
                                </mat-form-field>
                            </div>

                            <div class="form-group row3col">
                                <mat-form-field>
                                    <input formControlName="controlPrice" matInput type="number" placeholder="السعر">
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlPriceBeforeDiscount" matInput type="number"
                                        placeholder="السعر قبل الخصم">
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlQuantity" matInput type="number"
                                        placeholder="الكمية">
                                </mat-form-field>
                            </div>

                            <div class="form-group row3col">
                                <mat-checkbox class="example-margin mat-form-field" formControlName="controlIsSale">
                                    عرض خاص
                                </mat-checkbox>
                                <mat-checkbox class="example-margin mat-form-field" formControlName="controlIsSpecial">
                                    منتج مميز</mat-checkbox>
                                <mat-checkbox class="example-margin mat-form-field" formControlName="controlIsNew">
                                    منتج جديد</mat-checkbox>
                            </div>

                            <div class="row">
                                <div *ngIf="imageFile" class="center col-sm-6">
                                    <h6>صورة المنتج الرئيسية</h6>
                                    <img class="changeimage" [src]="imageFile" (click)="fileInput.click()" height="300"
                                        width="250"> <br />
                                </div>

                                <div *ngIf="imageFileFlip" class="center col-sm-6">
                                    <h6>صورة المنتج الفرعية</h6>
                                    <img class="changeimage" [src]="imageFileFlip" (click)="flipfileInput.click()"
                                        height="300" width="250"> <br />
                                </div>
                            </div>

                            <div *ngIf="isNew || product" class="form-group row1col">
                                <div class="center card-body dropzone-custom p-0">
                                    <h6>صور المنتج </h6>
                                    <div class="dropzone dropzone-primary" [dropzone]="config1"
                                        (error)="onUploadError($event)" (success)="onUploadSuccess($event)"
                                        (init)="nationalDropZoneInit($event)">
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">اسحب الصور هنا او اضغط هنا واختار الصور</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div class="modal-footer">
                            <button type="submit" [disabled]="isUploadPending"
                                class="btn btn-primary">Save</button>
                            <h3 *ngIf="isUploadPending">برجاء انتظار تحميل جميع الصور باقي
                                {{imagesLeft}} صورة </h3>

                            <!-- <button class="btn btn-secondary" type="button">Close</button> -->
                        </div>
                    </form>

                    <app-loading *ngIf="isSubmit" [progress]="progress"></app-loading>

                </div>
            </div>
        </div>
    </div>
</div>

<input #fileInput hidden type='file' accept="image/*" (change)="onFileSelected($event,fileInput,'file')">
<input #flipfileInput hidden type='file' accept="image/*" (change)="onFileSelected($event,flipfileInput,'flip')">