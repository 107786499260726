<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table [settings]="settings" [source]="colorsList">
                            </ng2-smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>