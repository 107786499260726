import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NewsModel } from 'src/app/shared/classes/newsModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from 'src/app/shared/service/news.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent {

  isNew: boolean = true;
  news: NewsModel;
  isSubmit: boolean = false;
  progress = 0;

  myForm = new FormGroup({
    controlArName: new FormControl("", [Validators.required]),
    controlEnName: new FormControl("", [Validators.required]),

    controlArLink: new FormControl(""),
    controlEnLink: new FormControl(""),
  });

  constructor(
    private newsService: NewsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.data.subscribe(data => {
      this.isNew = data.isNew;

      this.route.paramMap.subscribe(params => {
        const id = params.get('id');

        if (!this.isNew)
          this.newsService.getNewsByID(id).subscribe(e => {
            this.news = e;

            this.myForm.patchValue({
              controlArName: this.news.AR_Name,
              controlEnName: this.news.EN_Name,
              controlArLink: this.news.AR_Link,
              controlEnLink: this.news.EN_Link,
            });
          });
      });
    });
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }

  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;
    const newNews: NewsModel = {
      AR_Name: controls.controlArName.value,
      EN_Name: controls.controlEnName.value,

      AR_Link: controls.controlArLink.value,
      EN_Link: controls.controlEnLink.value,

      IsActive: true,
    }

    if (this.isNew) {
      this.newsService.addNews(newNews).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.newsService.updateNews(this.news.ID, newNews).subscribe((event) => this.saveSubscribe(event));
    }
  }

  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          this.router.navigate(['/design/list-news'])
        }, 2000);
        break;
    }
  }

  isValid(): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;

    return true;
  }
}
