import { Component, OnInit } from '@angular/core';
import { VisitorsService } from 'src/app/shared/service/visitors.service';

@Component({
  selector: 'app-countries-report',
  templateUrl: './countries-report.component.html',
  styleUrls: ['./countries-report.component.scss']
})
export class CountriesReportComponent {

  public counteryList: { Code: string, AR_Name: string, Views: number, Icon?: string }[] = []

  constructor(
    visitorService: VisitorsService,
  ) {
    visitorService.getCountriesReport().subscribe(response => {
      this.counteryList = response;

      this.counteryList.map(co => {
        var newURL = `<img src='assets/images/flags/48/${co.Code}.png' class='imgTable'>`
        co.Icon = newURL;
      });
    });
  }


  public settings = {
    pager: {
      perPage: 50,
    },

    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      Code: {
        title: 'ID',
        width: '100px',
        filter: false,
      },
      Icon: {
        title: 'علم الدوله',
        type: 'html',
        filter: false,
        width: '100px',
      },
      AR_Name: {
        title: 'اسم الدولة'
      },
      Views: {
        title: 'اجمالي المشاهدات',
        width: '250px',
      },
    },
  };
}