import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/shared/service/products.service';
import { Router } from '@angular/router';
import { ProductModel } from 'src/app/shared/classes/productModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  public productsList: ProductModel[] = []

  constructor(
    private productService: ProductsService,
    private router: Router,

  ) {
    //Get Categories
    this.loadProducts();
  }

  loadProducts() {
    this.productService.getAdminProducts().subscribe(response => {
      this.productsList = response.rows;
      this.productsList.forEach(e => {
        var newURL = `<img src='${environment.apiUrl + "/" + e.ImageUrl}' class='imgTable'>`
        e.CategoryARName = e.Category.AR_Name;
        e.ImageUrl = newURL;
      });
    });
  }


  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },
    pager: {
      perPage: 25,
    },

    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    columns: {
      ImageUrl: {
        title: 'صورة المنتج',
        type: 'html',
        filter: false,
        width: '100px',

      },
      AR_Name: {
        title: 'اسم المنتج'
      },
      CategoryARName: {
        title: 'اسم القسم',
      },
    },
  };


  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.productService.deleteProduct(event.data.ProductID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {

    if (window.confirm('Are you sure you want to Edit?')) {
      //Show Edit Window
      this.router.navigate(["/products/edit-product", event.data.ProductID]);
    }
  }

  ngOnInit() { }

}
