import { Component, OnInit } from '@angular/core';
import { VisitorsService } from 'src/app/shared/service/visitors.service';

@Component({
  selector: 'app-colors-report',
  templateUrl: './colors-report.component.html',
  styleUrls: ['./colors-report.component.scss']
})
export class ColorsReportComponent {

  public colorsList: { ID: number, AR_Name: string, Views: number }[] = []

  constructor(
    visitorService: VisitorsService,
  ) {
    visitorService.getColorsReport().subscribe(response => {
      this.colorsList = response;
    });
  }


  public settings = {
    pager: {
      perPage: 50,
    },

    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      // ID: {
      //   title: 'ID',
      //   width: '50px',
      //   filter: false,
      // },
      AR_Name: {
        title: 'اسم اللون'
      },
      Views: {
        title: 'اجمالي المشاهدات',
        width: '250px',
      },
    },
  };
}