<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>إضافة منتج جديد</h5>
                </div> -->
                <div class="card-body">

                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">

                        <div class="modal-body">

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <input formControlName="controlFacebook" matInput placeholder=" الفيس بوك">
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <input formControlName="controlYoutube" matInput placeholder=" اليوتيوب">
                                </mat-form-field>
                            </div>


                            <div class="form-group row1col">
                                <mat-form-field>
                                    <input formControlName="controlTwitter" matInput placeholder="تويتر">
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <input formControlName="controlInstagram" matInput placeholder="إنستجرام">
                                </mat-form-field>
                            </div>
                            <div class="form-group row1col">
                                <mat-form-field>
                                    <input formControlName="controlGoogle" matInput placeholder="جوجل بلص">
                                </mat-form-field>
                            </div>
                            <div class="form-group row1col">
                                <mat-form-field>
                                    <input formControlName="controlWhatsApp" matInput
                                        placeholder="رقم تليفون الواتس اب">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                            <!-- <button class="btn btn-secondary" type="button">Close</button> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>