<div class="page-wrapper" dir="rtl">
    <div class="authentication-box">
      <div class="container">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="card tab2-card">
              <div class="card-body center">
                <app-loading
                  *ngIf="isLoading"
                  title="جاري تسجيل الدخول..."
                  right="10%"
                ></app-loading>
  
                <ngb-tabset *ngIf="!isLoading" class="tab-coupon mb-0">
                  <ngb-tab>
                    <ng-template ngbTabTitle
                      ><i class="icon-user mr-2 space"></i>تسجيل
                      الدخول</ng-template
                    >
                    <ng-template ngbTabContent>
                      <div
                        class="tab-pane fade active show"
                        id="account"
                        role="tabpanel"
                        aria-labelledby="account-tab"
                      >
                        <form
                          [formGroup]="myForm"
                          class="form-horizontal auth-form"
                          novalidate=""
                          (ngSubmit)="onSubmit()"
                        >
                          <div class="form-group row1col">
                            <mat-form-field>
                              <input
                                formControlName="userNameControl"
                                matInput
                                placeholder="اسم المستخدم"
                              />
                              <mat-error
                                *ngIf="hasError('userNameControl', 'required')"
                              >
                                برجاء ادخال اسم المستخدم
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="form-group row1col">
                            <mat-form-field>
                              <input
                                formControlName="passwordControl"
                                type="password"
                                matInput
                                placeholder="كلمة المرور"
                              />
                              <mat-error
                                *ngIf="hasError('passwordControl', 'required')"
                              >
                                برجاء ادخال كلمة المرور
                              </mat-error>
                            </mat-form-field>
                          </div>
  
                          <div class="form-button">
                            <button class="btn btn-primary submit" type="submit">
                              تسجيل الدخول
                            </button>
                          </div>
                          <div class="form-footer">
                            <span> </span>
                          </div>
                        </form>
                      </div>
                    </ng-template>
                  </ngb-tab>
                </ngb-tabset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  