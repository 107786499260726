<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>إضافة منتج جديد</h5>
                </div> -->
                <div class="card-body">

                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">

                        <div class="modal-body">

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArName" matInput placeholder="الخبر - عربي">
                                    <mat-error *ngIf="hasError('controlArName','required')"> برجاء كتابة الخبر
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnName" matInput placeholder=" الخبر - إنجليزي ">
                                    <mat-error *ngIf="hasError('controlEnName','required')"> برجاء كتابة الخبر
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArLink" matInput placeholder=" الرابط - عربي">
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnLink" matInput placeholder="الرابط - إنجليزي ">
                                </mat-form-field>
                            </div>
                            <!-- 
                            <div class="form-group row1col">
                            <mat-slide-toggle formControlName="control÷sActive"> الحاله  ( ظهور - إخفاء )ـ</mat-slide-toggle> -->

                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                            <!-- <button class="btn btn-secondary" type="button">Close</button> -->
                        </div>
                    </form>

                    <app-loading *ngIf="isSubmit" [progress]="progress"></app-loading>
                </div>
            </div>
        </div>
    </div>
</div>