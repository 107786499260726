import { Component } from '@angular/core';
import { PartModel } from 'src/app/shared/classes/partModel';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PartsService } from 'src/app/shared/service/parts.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';

@Component({
  selector: 'app-add-part',
  templateUrl: './add-part.component.html',
  styleUrls: ['./add-part.component.scss']
})
export class AddPartComponent{

  isNew: boolean = true;
  part: PartModel;
  isSubmit: boolean = false;
  progress = 0;


  myForm = new FormGroup({
    controlArName: new FormControl("", [Validators.required]),
    controlEnName: new FormControl("", [Validators.required]),

    controlAR_Address: new FormControl("", [Validators.required]),
    controlEN_Address: new FormControl("", [Validators.required]),

    controlMapPath: new FormControl(""),

    controlArDescription: new FormControl(""),
    controlEnDescription: new FormControl(""),
  });

  constructor(
    private partsService: PartsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    //Get Categories
    this.route.data.subscribe(data => {
      this.isNew = data.isNew;

      this.route.paramMap.subscribe(params => {
        const id = params.get('id');

        if (!this.isNew)
          this.partsService.getPartByID(id).subscribe(e => {
            this.part = e;

            this.myForm.patchValue({
              controlArName: this.part.AR_Name,
              controlEnName: this.part.EN_Name,
              controlAR_Address: this.part.AR_Address,
              controlEN_Address: this.part.AR_Address,
              controlArDescription: this.part.AR_Description,
              controlEnDescription: this.part.EN_Description,
              controlMapPath: this.part.MapPath,
            });
          });
      });
    });
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }


  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;

    var mapPath: string = controls.controlMapPath.value ?? "";
    if (mapPath.indexOf("google") > 0) {
      var splits = mapPath.split("\"");
      mapPath = splits.find(e => e.indexOf("google") > 0);
    }
    else {
      mapPath = null;
    }

    const newPart: PartModel = {
      AR_Name: controls.controlArName.value,
      EN_Name: controls.controlEnName.value,

      AR_Address: controls.controlAR_Address.value,
      EN_Address: controls.controlEN_Address.value,

      AR_Description: controls.controlArDescription.value,
      EN_Description: controls.controlEnDescription.value,

      MapPath: mapPath,
    }

    if (this.isNew) {
      this.partsService.addPart(newPart).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.partsService.updatePart(this.part.ID, newPart).subscribe((event) => this.saveSubscribe(event));
    }
  }

  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          this.router.navigate(['/design/list-parts'])
        }, 2000);
        break;
    }
  }

  isValid(): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;

    return true;
  }

}
