import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { DashboardComponent } from './dashboard/dashboard.component'
import { CountToModule } from 'angular-count-to';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductsReportComponent } from './products-report/products-report.component';
import { CategoriesReportComponent } from './categories-report/categories-report.component';
import { PartsReportComponent } from './parts-report/parts-report.component';
import { ColorsReportComponent } from './colors-report/colors-report.component';
import { TagsReportComponent } from './tags-report/tags-report.component';
import { CountriesReportComponent } from './countries-report/countries-report.component';
import { MaterialsReportComponent } from './materials-report/materials-report.component';


@NgModule({
  declarations: [
    ReportsComponent,
    DashboardComponent,
    ProductsReportComponent,
    CategoriesReportComponent,
    PartsReportComponent,
    ColorsReportComponent,
    TagsReportComponent,
    CountriesReportComponent,
    MaterialsReportComponent,
    
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    CountToModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    Ng2SmartTableModule

  ]
})
export class ReportsModule { }
