import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EmployeeModel } from '../models/employee.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public user = new BehaviorSubject<EmployeeModel>(null);
  public currentUser: EmployeeModel;

  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.user.subscribe((user) => {
      this.currentUser = user ? user : null;
    });
  }

  getIsLoggedIn() {
    var isLoggedIn = this.currentUser && this.currentUser != null;
    if (!isLoggedIn) {
      var currentUserJson = sessionStorage.getItem('currentUser'); // if it's object

      if (currentUserJson) {
        console.log('Load from storage ');

        var userInStorage = JSON.parse(currentUserJson) as EmployeeModel; // if it's object
        this.user.next(userInStorage);
      }
    }

    return this.currentUser && this.currentUser != null;
  }
  getLoginID(): number {
    return +this.currentUser?.login[0]?.id ?? 0;
  }
  getCurrentUserID() {
    var id = +this.currentUser?.id;
    return isNaN(id) ? 0 : id;
  }

  showMessage(message, isSuccess): boolean {
    this._snackBar.open(`${isSuccess ? '✔' : '✖'} ${message}`, 'close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 2000,
      panelClass: isSuccess ? ['success-snackbar'] : ['error-snackbar'],
    });

    return isSuccess;
  }

  showAndRedirect(result, path) {
    this.showMessage(result.message, result?.isSuccess);
    this.navigate(path);
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.showMessage(errorMessage, false);
    return throwError(errorMessage);
  }

  confirmMessage({ title, message }): Promise<boolean> {
    return new Promise(async (resolve) => {
      const dialogData = new ConfirmDialogModel(
        title ?? 'Confirm Action',
        message ?? ''
      );

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '450px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        resolve(dialogResult);
      });
    });
  }

  youtube_parser(url: string) {
    try {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);

      return match && match[7] && match[7].length == 11;
    } catch (error) {
      return false;
    }
  }

  navigate(path: string) {
    setTimeout((e) => {
      this.router.navigate([path]);
    }, 500);
  }

  getTimeAgo(dateAndTime: string): string {
    var time = moment.duration(moment().diff(dateAndTime)).humanize(true);
    return time == 'in a few seconds' || time == 'a few seconds ago'
      ? 'Now'
      : time;
  }
}
