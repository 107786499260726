import { Component } from '@angular/core';
import { VisitorsService } from 'src/app/shared/service/visitors.service';

@Component({
  selector: 'app-products-report',
  templateUrl: './products-report.component.html',
  styleUrls: ['./products-report.component.scss']
})
export class ProductsReportComponent {

  public productsList: { ProductID: number, AR_Name: string, Views: number }[];

  constructor(
    visitorService: VisitorsService,
  ) {
    visitorService.getProductsReport().subscribe(response => {
      this.productsList = response;
    });
  }


  public settings = {
    pager: {
      perPage: 50,
    },

    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      // ProductID: {
      //   title: 'ID',
      //   width: '50px',
      //   filter: false,
      // },
      AR_Name: {
        title: 'اسم المنتج'
      },
      Views: {
        title: 'اجمالي المشاهدات',
        width: '250px',
      },
    },
  };


}
