import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/shared/service/settings.service';
import { Router } from '@angular/router';
import { NewsService } from 'src/app/shared/service/news.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
  public newsList = []

  constructor(
    private newsService: NewsService,
    private router: Router,
  ) {
    //Get Categories
    this.loadNews();
  }

  loadNews() {
    this.newsService.getNews().subscribe(response => {
      this.newsList = response;
    });
  }


  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },

    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    pager: {
      perPage: 25,
    },
    columns: {
      AR_Name: {
        title: 'الخبر'
      },
      Path:
      {
        title: 'المسار'
      },
    },
  };

  ngOnInit() { }

  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.newsService.deleteNews(event.data.ID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {
    if (window.confirm('Are you sure you want to Edit?')) {

      //Show Edit Window
      this.router.navigate(["/design/edit-news/", event.data.ID]);
    }
  }
}
