<!-- Container-fluid starts-->


<div class="container-fluid">

    <div class="row">
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'navigation'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">مشاهدات اليوم</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="totalViews" [from]="0"
                                    [duration]="2">0</span><small> مشاهده</small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'box'" class="font-secondary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">زوار اليوم</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="totalViewers" [from]="0"
                                    [duration]="2">0</span><small> زائر</small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'message-square'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">الدول</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="totalCounters" [from]="0"
                                    [duration]="2">0</span><small> دولة </small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">مشاهدات الرئيسية</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="totalHomePage" [from]="0"
                                    [duration]="2">0</span><small> مشاهده </small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5> احصائيات مشاهدات اليوم - بالساعات</h5>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="timeGraph" [datasets]="timeChartData" [labels]="timeChartLabels"
                        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>


        <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>اكثر المنتجات مشاهده اليوم</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table center">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">اسم المنتج</th>
                                    <th scope="col">عدد مشاهدات اليوم</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of products; let i = index">
                                    <td class="id">{{i+1}}</td>
                                    <td>{{product.Name}}</td>
                                    <td class="font-primary VisitorsCount">{{product.VisitorsCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a [routerLink]="'/reports/products'" class="btn btn-primary viewButton">عرض تقرير جميع
                            المنتجات</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>اكثر الاقسام مشاهده اليوم</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table center">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">اسم القسم</th>
                                    <th scope="col">عدد مشاهدات اليوم</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let category of categories ; let i = index">
                                    <td class="id">{{i+1}}</td>
                                    <td>{{category.Name}}</td>
                                    <td class="font-primary VisitorsCount">{{category.VisitorsCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a [routerLink]="'/reports/categories'" class="btn btn-primary viewButton">عرض تقرير جميع
                            الاقسام</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- 
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>تقرير زوار وزيارات البلاد </h5>
                </div>

                <div class="card-body" *ngIf="doughnutData">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="doughnutData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0"> اللغة العربية <span
                                                    class="pull-right">{{ar_Percent}}%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0"> اللغة الانجليزية <span
                                                    class="pull-right">{{en_Percent}}%</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xl-9 xl-100">
                            <div class="order-graph xl-space">
                                <div class="ct-4 flot-chart-container">
                                    <google-chart [data]="countryChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>اكثر الكلمات الدلاليه مشاهده اليوم</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table center">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">الكلمة الدلاليه</th>
                                    <th scope="col">عدد مشاهدات اليوم</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tag of tags; let i = index">
                                    <td class="id">{{i+1}}</td>
                                    <td>{{tag.Name}}</td>
                                    <td class="font-primary VisitorsCount">{{tag.VisitorsCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a [routerLink]="'/reports/tags'" class="btn btn-primary viewButton">عرض تقرير جميع الكلمات
                            الدلالية</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>اكثر الفروع مشاهده اليوم</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table center">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">اسم الفرع</th>
                                    <th scope="col">عدد مشاهدات اليوم</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let part of parts; let i = index">
                                    <td class="id">{{i+1}}</td>
                                    <td>{{part.Name}}</td>
                                    <td class="font-primary VisitorsCount">{{part.VisitorsCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a [routerLink]="'/reports/parts'" class="btn btn-primary viewButton">عرض تقرير جميع الفروع</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>اكثر الالوان مشاهده اليوم</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table center">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">اسم اللون</th>
                                    <th scope="col">عدد مشاهدات اليوم</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let color of colors; let i = index">
                                    <td class="id">{{i+1}}</td>
                                    <td>{{color.Name}}</td>
                                    <td class="font-primary VisitorsCount">{{color.VisitorsCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a [routerLink]="'/reports/colors'" class="btn btn-primary viewButton">عرض تقرير جميع
                            الالوان</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>اكثر الخامات مشاهده اليوم</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table center">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">اسم الخامه</th>
                                    <th scope="col">عدد مشاهدات اليوم</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let material of materials; let i = index">
                                    <td class="id">{{i+1}}</td>
                                    <td>{{material.Name}}</td>
                                    <td class="font-primary VisitorsCount">{{material.VisitorsCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a [routerLink]="'/reports/materials'" class="btn btn-primary viewButton">عرض تقرير جميع
                            الخامات</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Container-fluid Ends-->