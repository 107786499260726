<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>إضافة منتج جديد</h5>
                </div> -->
                <div class="card-body">

                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">

                        <div class="modal-body">

                            <div class="row">
                                <div class="col-sm-8">

                                    <div class="form-group row1col">
                                        <mat-form-field>
                                            <input formControlName="controlArTitle" matInput
                                                placeholder="اسم السلايدر - عربي">
                                            <mat-error *ngIf="hasError('controlArTitle','required')"> برجاء كتابة اسم
                                                السلايدر </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group row1col">
                                        <mat-form-field>
                                            <input formControlName="controlEnTitle" matInput
                                                placeholder="اسم السلايدر - إنجليزي ">
                                            <mat-error *ngIf="hasError('controlEnTitle','required')"> برجاء كتابة اسم
                                                السلايدر </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="exam-intro-image col-sm-4 center">
                                    <h6>لون خط اسم السلايدر </h6>
                                    <input formControlName="controlColorTitle" matInput class="color-picker"
                                        type="color">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-8">

                                    <div class="form-group row1col">
                                        <mat-form-field>
                                            <input formControlName="controlArSubTitle" matInput
                                                placeholder="اسم السلايدر الفرعي - عربي">
                                            <mat-error *ngIf="hasError('controlArSubTitle','required')"> برجاء كتابة اسم
                                                السلايدر الفرعي </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group row1col">
                                        <mat-form-field>
                                            <input formControlName="controlEnSubTitle" matInput
                                                placeholder="اسم السلايدر الفرعي - إنجليزي ">
                                            <mat-error *ngIf="hasError('controlEnSubTitle','required')"> برجاء كتابة اسم
                                                السلايدر الفرعي </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="exam-intro-image col-sm-4 center">
                                    <h6>لون خط اسم السلايدر الفرعي </h6>
                                    <input formControlName="controlColorSubTitle" matInput class="color-picker"
                                        type="color">
                                </div>
                            </div>


                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArButtonTitle" matInput
                                        placeholder="اسم الزرار - عربي">
                                    <mat-error *ngIf="hasError('controlArButtonTitle','required')"> برجاء كتابة اسم
                                        الزرار </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnButtonTitle" matInput
                                        placeholder="اسم الزرار - إنجليزي ">
                                    <mat-error *ngIf="hasError('controlEnButtonTitle','required')"> برجاء كتابة اسم
                                        الزرار </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArLink" matInput placeholder=" الرابط - عربي">
                                    <mat-error *ngIf="hasError('controlArLink','required')"> برجاء كتابة عنوان
                                        الرابط </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnLink" matInput placeholder="الرابط - إنجليزي ">
                                    <mat-error *ngIf="hasError('controlEnLink','required')"> برجاء كتابة عنوان
                                        الرابط </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <div *ngIf="imageFile" class="center col-sm-6">
                                    <h6>صورة السلايدر للكمبيوتر</h6>
                                    <img class="changeimage" [src]="imageFile" (click)="fileInput.click()" height="200"
                                        width="350"> <br />
                                </div>

                                <div *ngIf="imageFileMobile" class="center col-sm-6">
                                    <h6>صورة السلايدر للموبايل</h6>
                                    <img class="changeimage" [src]="imageFileMobile" (click)="mobilefileInput.click()"
                                        height="200" width="350"> <br />
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                            <!-- <button class="btn btn-secondary" type="button">Close</button> -->
                        </div>
                    </form>

                    <app-loading *ngIf="isSubmit" [progress]="progress"></app-loading>
                </div>
            </div>
        </div>
    </div>
</div>


<input #fileInput hidden type='file' accept="image/*" (change)="onFileSelected($event,fileInput,'file')">
<input #mobilefileInput hidden type='file' accept="image/*" (change)="onFileSelected($event,mobilefileInput,'mobile')">