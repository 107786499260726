import { Component, OnInit } from '@angular/core';
import { ColorModel } from 'src/app/shared/classes/colorModel';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ProductsService } from 'src/app/shared/service/products.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-add-color',
  templateUrl: './add-color.component.html',
  styleUrls: ['./add-color.component.scss']
})
export class AddColorComponent {

  isNew: boolean = true;
  color: ColorModel;
  isSubmit: boolean = false;
  progress: number = 0;

  myForm = new FormGroup({
    controlArName: new FormControl("", [Validators.required]),
    controlEnName: new FormControl("", [Validators.required]),
    controlColor: new FormControl("#000000", [Validators.required]),

    controlArDescription: new FormControl(""),
    controlEnDescription: new FormControl(""),
  });

  constructor(private productService: ProductsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    //Get Categories
    this.route.data.subscribe(data => {
      this.isNew = data.isNew;

      this.route.paramMap.subscribe(params => {
        const id = params.get('id');

        if (!this.isNew)
          this.productService.getColorByID(id).subscribe(e => {
            this.color = e;

            this.myForm.patchValue({
              controlArName: this.color.AR_Name,
              controlEnName: this.color.EN_Name,
              controlColor: this.color.Code,
              controlArDescription: this.color.AR_Description,
              controlEnDescription: this.color.EN_Description,
            });
          });
      });
    });
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }


  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;
    const new_color: ColorModel = {
      AR_Name: controls.controlArName.value,
      AR_Description: controls.controlArDescription.value,
      Code: controls.controlColor.value,
      EN_Name: controls.controlEnName.value,
      EN_Description: controls.controlEnDescription.value,
    }

    //Call Upload Image then ;
    if (this.isNew) {
      this.productService.addColor(new_color).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.productService.updateColor(this.color.ID, new_color).subscribe((event) => this.saveSubscribe(event));
    }
  }

  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          this.router.navigate([`/products/list-color`]);
        }, 2000);
        break;
    }
  }

  isValid(): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;

    return true;
  }
}
