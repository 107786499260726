<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">
                        <div class="modal-body">
                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArName" matInput placeholder="اسم الفرع - عربي">
                                    <mat-error *ngIf="hasError('controlArName','required')"> برجاء كتابة اسم
                                        الفرع </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnName" matInput placeholder="اسم الفرع - إنجليزي ">
                                    <mat-error *ngIf="hasError('controlEnName','required')"> برجاء كتابة اسم
                                        الفرع </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlAR_Address" matInput
                                        placeholder=" عنوان الفرع - عربي">
                                    <mat-error *ngIf="hasError('controlAR_Address','required')"> برجاء كتابة عنوان
                                        الفرع </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEN_Address" matInput
                                        placeholder="عنوان الفرع - إنجليزي ">
                                    <mat-error *ngIf="hasError('controlEN_Address','required')"> برجاء كتابة عنوان
                                        الفرع </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArDescription" matInput
                                        placeholder="تفاصيل الفرع - عربي">
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnDescription" matInput
                                        placeholder="تفاصيل الفرع - إنجليزي ">
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <textarea formControlName="controlMapPath" matInput placeholder=" رابط خريطه جوجل">
                                        </textarea>
                                </mat-form-field>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </form>

                    <app-loading *ngIf="isSubmit" [progress]="progress"></app-loading>
                </div>
            </div>
        </div>
    </div>
</div>