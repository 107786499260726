import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SliderListComponent } from './slider/slider-list/slider-list.component';
import { AddSliderComponent } from './slider/add-slider/add-slider.component';
import { NewsListComponent } from './news/news-list/news-list.component';
import { AddNewsComponent } from './news/add-news/add-news.component';
import { PhoneListComponent } from './phone/phone-list/phone-list.component';
import { AddPhoneComponent } from './phone/add-phone/add-phone.component';
import { PartListComponent } from './part/part-list/part-list.component';
import { AddPartComponent } from './part/add-part/add-part.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'list-sliders',
                component: SliderListComponent,
                data: {
                    title: "سلايدر",
                    breadcrumb: "سلايدر"
                }
            },
            {
                path: 'add-slider',
                component: AddSliderComponent,
                data: {
                    title: "اضافة سلايدر",
                    breadcrumb: "إضافة سلايدر",
                    isNew: true,
                }
            },
            {
                path: 'edit-slider/:id',
                component: AddSliderComponent,
                data: {
                    title: "تعديل السلايدر",
                    breadcrumb: "تعديل السلايدر",
                    isNew: false,
                }
            },
            {
                path: 'list-phones',
                component: PhoneListComponent,
                data: {
                    title: "ارقام التليفون",
                    breadcrumb: "ارقام التليفون"
                }
            },
            {
                path: 'add-phone',
                component: AddPhoneComponent,
                data: {
                    title: "اضافة رقم",
                    breadcrumb: "إضافة رقم",
                    isNew: true,

                }
            },
            {
                path: 'edit-phone/:id',
                component: AddPhoneComponent,
                data: {
                    title: "تعديل الرقم",
                    breadcrumb: "تعديل الرقم",
                    isNew: false,
                }
            },

            {
                path: 'list-parts',
                component: PartListComponent,
                data: {
                    title: "الفروع",
                    breadcrumb: "الفروع"
                }
            },
            {
                path: 'add-part',
                component: AddPartComponent,
                data: {
                    title: "اضافة فرع",
                    breadcrumb: "إضافة فرع",
                    isNew: true,
                }
            },
            {
                path: 'edit-part/:id',
                component: AddPartComponent,
                data: {
                    title: "تعديل فرع",
                    breadcrumb: "تعديل فرع",
                    isNew: false,
                }
            },

            {
                path: 'list-news',
                component: NewsListComponent,
                data: {
                    title: "الاخبار",
                    breadcrumb: "الاخبار"
                }
            },
            {
                path: 'add-news',
                component: AddNewsComponent,
                data: {
                    title: "اضافة خبر",
                    breadcrumb: "إضافة خبر",
                    isNew: true,
                }
            },
            {
                path: 'edit-news/:id',
                component: AddNewsComponent,
                data: {
                    title: "تعديل الخبر",
                    breadcrumb: "تعديل الخبر",
                    isNew: false,
                }
            },

        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DesignRoutingModule { }
