import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from 'src/app/shared/models/login.model';
import { SEOService } from 'src/app/shared/service/SEOService';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  myForm: FormGroup;
  isLoading = false;
  isSubmit = false;

  constructor(
    private seoService: SEOService,
    private userService: UserService
  ) {}

  initForm() {
    this.myForm = new FormGroup({
      userNameControl: new FormControl('', [Validators.required]),
      passwordControl: new FormControl('', [Validators.required]),
    });
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }

  ngOnInit() {
    this.seoService.updateTitle('تسجيل الدخول');
    this.initForm();
  }

  onSubmit() {
    if (!this.myForm.valid) return;

    const login = this.getLoginUserModel();
    if (!this.isValid(login)) {
      this.isSubmit = false;
      this.isLoading = false;
      return;
    }

    this.userService
      .login(login)
      .then((user) => {
        if (user) {
          sessionStorage.setItem('currentUser', JSON.stringify(user)); // if it's object

          this.userService.helper.user.next(user);
          this.userService.helper.navigate('/');
        } else {
          this.isSubmit = false;
          this.isLoading = false;

          return this.userService.helper.showMessage(
            'Please Try again !',
            false
          );
        }
      })
      .catch((error) => {
        this.isSubmit = false;
        this.isLoading = false;

        return this.userService.helper.showMessage(error, false);
      });
  }

  getLoginUserModel(): LoginModel {
    const controls = this.myForm.controls;

    var login: LoginModel = {
      userName: controls.userNameControl.value,
      password: controls.passwordControl.value,
    };

    return login;
  }

  isValid(employee: LoginModel): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;
    this.isLoading = true;

    if (employee.password.length < 4)
      return this.userService.helper.showMessage(
        'عفوا الرقم السري يجب ان يكون علي الاقل 4 ارقام',
        false
      );

    return true;
  }
}
