<h1 class="center" mat-dialog-title>
  {{ title }}
</h1>

<div class="center" mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div class="buttons_container" mat-dialog-actions>
  <!-- <button mat-button (click)="onDismiss()">No</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button> -->

  <a class="btn btn-secondary no" (click)="onDismiss()">لا</a>
  <a class="btn btn-primary yes" (click)="onConfirm()">نعم</a>
</div>
