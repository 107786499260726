<div class="container-fluid" *ngIf="isNew || category">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>إضافة منتج جديد</h5>
                </div> -->
                <div class="card-body">

                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">

                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="form-group row1col">
                                        <mat-form-field>
                                            <input formControlName="controlArName" matInput
                                                placeholder="اسم القسم - عربي">
                                            <mat-error *ngIf="hasError('controlArName','required')"> برجاء كتابة اسم
                                                القسم </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group row1col">

                                        <mat-form-field>
                                            <input formControlName="controlEnName" matInput
                                                placeholder="اسم القسم - إنجليزي ">
                                            <mat-error *ngIf="hasError('controlEnName','required')"> برجاء كتابة اسم
                                                القسم </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div *ngIf="imageFile" class="exam-intro-image center col-sm-4">
                                    <img [src]="imageFile" class="changeimage" (click)="fileInput.click()" height="150"
                                        width="150">
                                </div>
                            </div>

                            <div class="form-group row1col space-top">
                                <mat-form-field>
                                    <textarea formControlName="controlArDescription" matInput
                                        placeholder="وصف القسم - عربي">
                                        </textarea>
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <textarea formControlName="controlEnDescription" matInput
                                        placeholder="وصف القسم - انجليزي">
                                    </textarea>
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field class="example-chip-list">
                                    <mat-chip-list #chipList aria-label="Fruit selection">
                                        <mat-chip *ngFor="let tag of arTags" [selectable]="false" [removable]="true"
                                            (removed)="removeTag(tag,'ar')">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="الكلمات الدلالية - عربي " [matChipInputFor]="chipList"
                                            formControlName="controlArTags"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addTag($event,'ar')">
                                    </mat-chip-list>
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field class="example-chip-list">
                                    <mat-chip-list #enTagControl aria-label="Fruit selection">
                                        <mat-chip *ngFor="let tag of enTags" [selectable]="false" [removable]="true"
                                            (removed)="removeTag(tag,'en')">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="الكلمات الدلالية - انجليزي "
                                            [matChipInputFor]="enTagControl" formControlName="controlEnTags"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addTag($event,'en')">
                                    </mat-chip-list>
                                </mat-form-field>
                            </div>

                            <div *ngIf="isSubCategory" class="form-group row1col">
                                <mat-form-field>
                                    <mat-label>القسم الرئيسي</mat-label>
                                    <mat-select formControlName="controlParent" [compareWith]="compareFn">
                                        <mat-option *ngFor="let parent of parents " [value]="parent.CategoryID">
                                            {{parent.AR_Name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('controlParent','required')"> برجاء اختيار
                                        القسم الرئيسي </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- <div class="form-group mb-0">
                                <input class="form-control" #image type="file" accept="image/*"
                                    (change)="onFileSelected($event,image)">
                            </div> -->

                            <div class="form-group row3col">

                                <mat-checkbox class="example-margin mat-form-field"
                                    formControlName="controlShowInHeader">
                                    عرض في الهيدر
                                </mat-checkbox>
                                <mat-checkbox class="example-margin mat-form-field" formControlName="controlShowInHome">
                                    عرض في الصفحة الرئيسية</mat-checkbox>
                                <mat-checkbox class="example-margin mat-form-field"
                                    formControlName="controlShowInFooter">
                                    عرض في الفوتر</mat-checkbox>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <input formControlName="controlSort" matInput type="number"
                                        placeholder="ترتيب القسم">
                                </mat-form-field>
                            </div>


                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                            <!-- <button class="btn btn-secondary" type="button">Close</button> -->
                        </div>
                    </form>

                    <app-loading *ngIf="isSubmit" [progress]="progress"></app-loading>

                </div>
            </div>
        </div>
    </div>
</div>


<input #fileInput hidden type='file' accept="image/*" (change)="onFileSelected($event,fileInput)">