import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './products/product-list/product-list.component';
import { AddProductComponent } from './products/add-product/add-product.component';
import { CategoryListComponent } from './categories/category-list/category-list.component';
import { AddCategoryComponent } from './categories/add-category/add-category.component';
import { MaterialListComponent } from './materials/material-list/material-list.component';
import { AddMaterialComponent } from './materials/add-material/add-material.component';
import { ColorListComponent } from './colors/color-list/color-list.component';
import { AddColorComponent } from './colors/add-color/add-color.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'list-categories',
                component: CategoryListComponent,
                data: {
                    title: "الاقسام الرئيسية",
                    breadcrumb: "الاقسام الرئيسية",
                    buttonTitle: "إضافة قسم رئيسي",

                    isSubCategory: false,
                }
            },
            {
                path: 'add-category',
                component: AddCategoryComponent,
                data: {
                    title: "اضافة قسم رئيسي",
                    breadcrumb: "اضافة قسم رئيسي",
                    isNew : true,
                }
            },
            {
                path: 'edit-category/:id',
                component: AddCategoryComponent,
                data: {
                    title: "تعديل قسم رئيسي",
                    breadcrumb: "تعديل قسم رئيسي",
                    isSubCategory: false,
                    isNew : false,
                }
            },

            {
                path: 'list-sub-categories',
                component: CategoryListComponent,
                data: {
                    title: "الاقسام الفرعية",
                    breadcrumb: "الاقسام الفرعية",
                    buttonTitle: "إضافة قسم فرعي",
                    isSubCategory: true,
                }
            },
            {
                path: 'add-sub-category',
                component: AddCategoryComponent,
                data: {
                    title: "اضافة قسم فرعي",
                    breadcrumb: "اضافة قسم فرعي",
                    isSubCategory: true,
                    isNew : true,
                }
            },
            {
                path: 'edit-sub-category/:id',
                component: AddCategoryComponent,
                data: {
                    title: "تعديل قسم فرعي",
                    breadcrumb: "تعديل قسم فرعي",
                    isSubCategory: true,
                    isNew : false,
                }
            },
            {
                path: 'list-product',
                component: ProductListComponent,
                data: {
                    title: "المنتجات",
                    breadcrumb: "المنتجات"
                }
            },
            {
                path: 'add-product',
                component: AddProductComponent,
                data: {
                    title: "اضافة منتج",
                    breadcrumb: "اضافة منتج",
                    isNew : true,
                }
            },
            {
                path: 'edit-product/:id',
                component: AddProductComponent,
                data: {
                    title: "تعديل المنتج",
                    breadcrumb: "تعديل المنتج",
                    isNew : false,
                }
            },

            {
                path: 'list-material',
                component: MaterialListComponent,
                data: {
                    title: "الخامات",
                    breadcrumb: "الخامات"
                }
            },
            {
                path: 'add-material',
                component: AddMaterialComponent,
                data: {
                    title: "اضافة خامة",
                    breadcrumb: "اضافة خامة",
                    isNew : true,
                }
            },
            {
                path: 'edit-material/:id',
                component: AddMaterialComponent,
                data: {
                    title: "تعديل الخامة",
                    breadcrumb: "تعديل الخامة",
                    isNew : false,
                }
            },

            
            {
                path: 'list-color',
                component: ColorListComponent,
                data: {
                    title: "الوان المنتجات",
                    breadcrumb: "الوان المنتجات"
                }
            },
            {
                path: 'add-color',
                component: AddColorComponent,
                data: {
                    title: "اضافة لون",
                    breadcrumb: "اضافة لون",
                    isNew : true,
                }
            },
            {
                path: 'edit-color/:id',
                component: AddColorComponent,
                data: {
                    title: "تعديل اللون",
                    breadcrumb: "تعديل اللون",
                    isNew : false,
                }
            },
            //       {
            //         path: 'product-detail',
            //         component: ProductDetailComponent,
            //         data: {
            //           title: "تفاصيل المنتج",
            //           breadcrumb: "تفاصيل المنتج"
            //         }
            //       },

            //       {
            //         path: 'product/:id',
            //         component: AddProductComponent,
            //         data: {
            //           title: "تعديل منتج",
            //           breadcrumb: "تعديل منتج"
            //         }
            //       },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductRoutingModule { }
