import { Injectable } from '@angular/core';
import { NewsModel } from '../classes/newsModel';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient,
    private settingService: SettingsService) {
  }

  getNews(): Observable<NewsModel[]> {
    var url = `${environment.apiUrl}/news/select/`;
    return this.http.get<NewsModel[]>(url);
  }

  getNewsByID(id: string): Observable<NewsModel> {
    return this.http.get<NewsModel>(`${environment.apiUrl}/news/select/${id}`);
  }

  addNews(news: NewsModel) {
    var url = `${environment.apiUrl}/news/add`;
    return this.http.post(url, news, { reportProgress: true, observe: 'events' })
      .pipe(catchError(this.settingService.errorMgmt))
  }

  updateNews(id: number, news: NewsModel) {
    const url = `${environment.apiUrl}/news/update/${id}`;
    return this.http.put(url, news, { reportProgress: true, observe: 'events' })
      .pipe(catchError(this.settingService.errorMgmt))
  }

  deleteNews(id: number) {
    const url = `${environment.apiUrl}/news/delete/${id}`;
    return this.http.put(url, null);
  }
}
