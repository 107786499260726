import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/shared/service/category.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  public categories = []
  isSubCategory: boolean = true;
  buttonTitle: string;
  constructor(private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    //Get Categories
    this.route.data.subscribe(data => {

      this.isSubCategory = data.isSubCategory;
      this.buttonTitle = data.buttonTitle;

      this.loadCategories();
    });

  }

  loadCategories() {
    this.categoryService.getCategories().subscribe(response => {
      if (this.isSubCategory) {
        this.categories = response.filter(e => e.ParentID != 0);
      }
      else {
        this.categories = response.filter(e => e.ParentID == 0)
      }

      this.categories.map(category => {
        var newURL = `<img src='${category.ImageUrl}' class='imgTable'>`
        category.ImageUrl = newURL;
      });
    });
  }

  settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },

    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    pager: {
      perPage: 25,
    },
    columns: {
      ImageUrl: {
        title: 'صورة القسم',
        type: 'html',
        filter: false,
        width: '100px',
      },
      AR_Name: {
        title: 'اسم القسم',
      },
    },
  };

  ngOnInit() {
  }

  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.categoryService.delete(event.data.CategoryID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {
    if (window.confirm('Are you sure you want to Edit?')) {

      //Show Edit Window
      const path = this.isSubCategory ? "edit-sub-category" : "edit-category";
      this.router.navigate(["/products", path, event.data.CategoryID]);
    }
  }
}