import { Component, OnInit } from '@angular/core';
import { MaterialModel } from 'src/app/shared/classes/materialModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductsService } from 'src/app/shared/service/products.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.scss']
})
export class AddMaterialComponent {

  isNew: boolean = true;
  material: MaterialModel;
  isSubmit: boolean = false;
  progress: number = 0;

  myForm = new FormGroup({
    controlArName: new FormControl("", [Validators.required]),
    controlEnName: new FormControl("", [Validators.required]),

    controlArDescription: new FormControl(""),
    controlEnDescription: new FormControl(""),
  });

  constructor(private productService: ProductsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    //Get Categories
    this.route.data.subscribe(data => {
      this.isNew = data.isNew;

      this.route.paramMap.subscribe(params => {
        const id = params.get('id');

        if (!this.isNew)
          this.productService.getMaterialByID(id).subscribe(e => {
            this.material = e;

            this.myForm.patchValue({
              controlArName: this.material.AR_Name,
              controlEnName: this.material.EN_Name,

              controlArDescription: this.material.AR_Description,
              controlEnDescription: this.material.EN_Description,
            });
          });
      });
    });
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }


  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;
    const new_material: MaterialModel = {
      AR_Name: controls.controlArName.value,
      AR_Description: controls.controlArDescription.value,

      EN_Name: controls.controlEnName.value,
      EN_Description: controls.controlEnDescription.value,
    }

    //Call Upload Image then ;
    if (this.isNew) {
      this.productService.addMaterial(new_material).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.productService.updateMaterial(this.material.MaterialID, new_material).subscribe((event) => this.saveSubscribe(event));
    }
  }


  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          this.router.navigate([`/products/list-material`]);
        }, 2000);
        break;
    }
  }

  isValid(): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;

    return true;
  }
}
