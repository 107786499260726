import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/shared/service/category.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryModel } from 'src/app/shared/classes/categoryModel';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

  isNew: boolean = true;
  isSubCategory: boolean = false;
  category: CategoryModel;
  isSubmit: boolean = false;
  progress: number = 0;

  selectedFile = null;
  defaultImagePath = "uploads/default/defaultCategory.png";
  imageFile;

  parents: CategoryModel[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  arTags: string[] = [];
  enTags: string[] = [];

  myForm = new FormGroup({
    controlArName: new FormControl("", [Validators.required]),
    controlEnName: new FormControl("", [Validators.required]),
    controlParent: new FormControl(""),

    controlArDescription: new FormControl(""),
    controlEnDescription: new FormControl(""),
    controlArTags: new FormControl(""),
    controlEnTags: new FormControl(""),

    controlShowInHeader: new FormControl(true),
    controlShowInHome: new FormControl(true),
    controlShowInFooter: new FormControl(true),
    controlSort: new FormControl(0),
  });

  constructor(private categoryService: CategoryService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    //Get Categories
    this.route.data.subscribe(data => {
      this.isSubCategory = data.isSubCategory;
      this.isNew = data.isNew;
      this.imageFile = environment.apiUrl + "/" + this.defaultImagePath;

      if (this.isSubCategory) {
        this.categoryService.getMainCategories().subscribe(data => {
          this.parents = data.rows;
        })
        this.myForm.controls.controlParent.setValidators([Validators.required]);
      }

      this.route.paramMap.subscribe(params => {
        const id = params.get('id');

        if (!this.isNew)
          this.categoryService.getCategoryByID(id).subscribe(e => {
            this.category = e;
            this.imageFile = this.category.ImageUrl;
            this.myForm.patchValue({
              controlArName: this.category.AR_Name,
              controlEnName: this.category.EN_Name,
              controlParent: this.category.ParentID,

              controlArDescription: this.category.AR_Description,
              controlEnDescription: this.category.EN_Description,

              controlShowInHeader: this.category.ShowInHeader,
              controlShowInHome: this.category.ShowInMainPage,
              controlShowInFooter: this.category.ShowInFooter,
              controlSort: this.category.Sort_index,
            });

            this.arTags = this.category?.AR_Tags?.split(',').filter(e => e != "");
            this.enTags = this.category?.EN_Tags?.split(',').filter(e => e != "");
          });
      });
    });
  }

  compareFn(x: number, y: number): boolean {
    return x && y ? x === y : x === y;
  }

  addTag(event: MatChipInputEvent, lang: string): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (lang == 'ar') {
        this.arTags.push(value.trim());
        this.myForm.controls["controlArTags"].setValue(null);
      }
      else if (lang == 'en') {
        this.enTags.push(value.trim());
        this.myForm.controls["controlEnTags"].setValue(null);
      }
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string, lang: string): void {
    var index = 0;

    switch (lang) {
      case 'ar':
        index = this.arTags.indexOf(tag);
        if (index < 0) return;

        this.arTags.splice(index, 1);
        return;

      case 'en':
        index = this.enTags.indexOf(tag);
        if (index < 0) return;

        this.enTags.splice(index, 1);
        return;
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }


  ngOnInit() {
  }

  onFileSelected(event, img) {
    if (event.target.files.length == 0) return;

    if (event.target.files[0]) {
      const file: File = event.target.files[0];
      var pattern = /image-*/;

      if (!file.type.match(pattern)) {
        alert('Invalid format');
        img.value = "";
        this.selectedFile = null;
        this.imageFile = environment.apiUrl + this.defaultImagePath;
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = event.target.files[0];

      reader.onload = (e: any) => {
        this.imageFile = e.target.result
      }
      // here you can do whatever you want with your image. Now you are sure that it is an image
    }
  }

  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;
    const cate: CategoryModel = {
      AR_Name: controls.controlArName.value,
      AR_Description: controls.controlArDescription.value,
      AR_Tags: this.arTags.join(),

      EN_Name: controls.controlEnName.value,
      EN_Description: controls.controlEnDescription.value,
      EN_Tags: this.enTags.join(),
      ImageUrl: this.defaultImagePath,

      ShowInHeader: controls.controlShowInHeader.value ? true : false,
      ShowInMainPage: controls.controlShowInHome.value ? true : false,
      ShowInFooter: controls.controlShowInFooter.value ? true : false,
      ParentID: this.isSubCategory ? controls.controlParent.value : 0,
      Sort_index: controls.controlSort.value ? controls.controlSort.value : 0,
    }

    var formData: any = new FormData();
    formData.append('category', JSON.stringify(cate));

    //Call Upload Image then ;
    if (this.selectedFile) {
      formData.append('file', this.selectedFile);
    }

    //Call Upload Image then ;
    if (this.isNew) {
      this.categoryService.addCategory(formData).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.categoryService.update(this.category.CategoryID, formData).subscribe((event) => this.saveSubscribe(event));
    }
  }

  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          const listpath = this.isSubCategory ? 'list-sub-categories' : 'list-categories';
          this.router.navigate([`/products/${listpath}`]);
        }, 2000);
        break;
    }
  }


  isValid(): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;

    return true;
  }
}
