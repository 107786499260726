<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <button type="button" class="btn btn-primary"
                        [routerLink]="['/products',isSubCategory ? 'add-sub-category' : 'add-category']">
                        {{buttonTitle}}
                    </button>
                </div>
                <div class="card-body">
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table [settings]="settings" [source]="categories"
                                (userRowSelect)="onEditConfirm($event)" (deleteConfirm)="onDeleteConfirm($event)">
                            </ng2-smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>