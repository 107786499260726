import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PhonesModel } from 'src/app/shared/classes/phonesModel';
import { SettingsService } from 'src/app/shared/service/settings.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PhonesService } from 'src/app/shared/service/phones.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';

@Component({
  selector: 'app-add-phone',
  templateUrl: './add-phone.component.html',
  styleUrls: ['./add-phone.component.scss']
})
export class AddPhoneComponent  {

  isNew: boolean = true;
  phone: PhonesModel;
  isSubmit: boolean = false;
  progress = 0;

  myForm = new FormGroup({
    controlPhone: new FormControl("", [Validators.required]),
  });


  constructor(
    private phoneService: PhonesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.data.subscribe(data => {
      this.isNew = data.isNew;

      this.route.paramMap.subscribe(params => {
        const id = params.get('id');

        if (!this.isNew)
          this.phoneService.getPhonesByID(id).subscribe(e => {
            this.phone = e;

            this.myForm.patchValue({
              controlPhone: this.phone.Phone,
            });
          });
      });
    });
    //CheckForEdit;
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.myForm.controls[controlName].hasError(errorName);
  }

  onSave() {
    if (!this.myForm.valid) return;
    if (!this.isValid()) return;

    //Get Group;
    const controls = this.myForm.controls;
    const newPhone: PhonesModel = {
      Phone: controls.controlPhone.value,
    }

    if (this.isNew) {
      this.phoneService.addPhones(newPhone).subscribe((event) => this.saveSubscribe(event));
    }
    else {
      this.phoneService.updatePhones(this.phone.ID, newPhone).subscribe((event) => this.saveSubscribe(event));
    }

  }
  saveSubscribe(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        // console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        // console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        setTimeout(e => {
          this.isSubmit = false;
          this.progress = 0;
          this.router.navigate(['/design/list-phones'])
        }, 2000);
        break;
    }
  }

  isValid(): boolean {
    if (this.isSubmit) return false;
    this.isSubmit = true;

    return true;
  }

}