import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { GalleryModule } from '@ks89/angular-modal-gallery';

var sharedDeclarations = [
  ToggleFullscreenDirective,
  FeatherIconsComponent,
  FooterComponent,
  HeaderComponent,
  SidebarComponent,
  ContentLayoutComponent,
  BreadcrumbComponent,
  RightSidebarComponent,
  LoadingComponent,
  ConfirmDialogComponent,
];


var sharedModules = [
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSnackBarModule,
  MatDialogModule,
  Ng2SmartTableModule,
  MatChipsModule,
  MatAutocompleteModule,
  FormsModule,
  NgbModule,
  DropzoneModule,
  GalleryModule.forRoot(),

  
];

@NgModule({
  declarations: [ sharedDeclarations ],
  imports: [CommonModule, RouterModule],

  providers: [NavService, WINDOW_PROVIDERS],
  exports: [sharedDeclarations, sharedModules],
})
export class SharedModule { }
