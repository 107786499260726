<div class="container-fluid" *ngIf="isNew || material">
    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="myForm" *ngIf="!isSubmit" (ngSubmit)="onSave()">

                        <div class="modal-body">
                            <div class="form-group row2col">
                                <mat-form-field>
                                    <input formControlName="controlArName" matInput placeholder="اسم الخامة - عربي">
                                    <mat-error *ngIf="hasError('controlArName','required')"> برجاء كتابة اسم
                                        الخامة </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <input formControlName="controlEnName" matInput placeholder="اسم الخامة - إنجليزي ">
                                    <mat-error *ngIf="hasError('controlEnName','required')"> برجاء كتابة اسم
                                        الخامة </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col space-top">
                                <mat-form-field>
                                    <textarea formControlName="controlArDescription" matInput
                                        placeholder="وصف الخامة - عربي">
                                        </textarea>
                                </mat-form-field>
                            </div>

                            <div class="form-group row1col">
                                <mat-form-field>
                                    <textarea formControlName="controlEnDescription" matInput
                                        placeholder="وصف الخامة - انجليزي">
                                    </textarea>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                            <!-- <button class="btn btn-secondary" type="button">Close</button> -->
                        </div>
                    </form>
                    <app-loading *ngIf="isSubmit" [progress]="progress"></app-loading>

                </div>
            </div>
        </div>
    </div>
</div>