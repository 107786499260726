import { Injectable } from '@angular/core';
import { SliderModel } from '../classes/sliderModel';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor(private http: HttpClient,
    private settingService: SettingsService) {
  }

  getSlider(): Observable<SliderModel[]> {
    var url = `${environment.apiUrl}/sliders/select/`;
    return this.http.get<SliderModel[]>(url);
  }

  getSliderByID(id: string): Observable<SliderModel> {
    return this.http.get<SliderModel>(`${environment.apiUrl}/sliders/select/${id}`);
  }

  addSlider(slider) {
    var url = `${environment.apiUrl}/sliders/add`;
    return this.http.post(url, slider, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  updateSlider(id: number, slider) {
    const url = `${environment.apiUrl}/sliders/update/${id}`;
    return this.http.put(url, slider, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  deleteSlider(id: number) {
    const url = `${environment.apiUrl}/sliders/delete/${id}`;
    return this.http.put(url, null);
  }

}
