import { Injectable } from '@angular/core';
import { PhonesModel } from '../classes/phonesModel';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhonesService {

  constructor(private http: HttpClient,
    private settingService: SettingsService) {
  }

  getPhones(): Observable<PhonesModel[]> {
    var url = `${environment.apiUrl}/phones/select/`;
    return this.http.get<PhonesModel[]>(url);
  }

  getPhonesByID(id: string): Observable<PhonesModel> {
    return this.http.get<PhonesModel>(`${environment.apiUrl}/phones/select/${id}`);
  }

  addPhones(phone: PhonesModel) {
    var url = `${environment.apiUrl}/phones/add`;
    return this.http.post(url, phone, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  updatePhones(id: number, phone: PhonesModel) {
    const url = `${environment.apiUrl}/phones/update/${id}`;
    return this.http.put(url, phone, { reportProgress: true, observe: 'events' })
    .pipe(catchError(this.settingService.errorMgmt))
  }

  deletePhones(id: number) {
    const url = `${environment.apiUrl}/phones/delete/${id}`;
    return this.http.put(url, null);
  }
}
