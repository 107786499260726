import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/shared/service/products.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-color-list',
  templateUrl: './color-list.component.html',
  styleUrls: ['./color-list.component.scss']
})
export class ColorListComponent {

  public colorsList = []

  constructor(
    private productService: ProductsService,
    private router: Router,

  ) {
    //Get Categories
    this.loadColors();
  }

  loadColors() {
    this.productService.getColors().subscribe(response => {
      this.colorsList = response;
    });
  }


  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },

    actions: {
      add: false,
      edit: false,
      position: 'right'
    },
    pager: {
      perPage: 25,
    },
    columns: {
      AR_Name: {
        title: 'اسم اللون'
      },
      AR_Description: {
        title: 'وصف اللون'
      },
    },
  };


  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.productService.deleteColor(event.data.ID).subscribe(res => {
        // this.redirctToList();
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onEditConfirm(event) {
    if (window.confirm('Are you sure you want to Edit?')) {

      //Show Edit Window
      this.router.navigate(["/products/edit-color", event.data.ID]);
    }
  }

}
